import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Switch, InputNumber, Spin, Typography} from "antd";
import basicStyle from "@iso/assets/styles/constants";
import {useDispatch, useSelector} from "react-redux";
import {validateFromServer} from "../../../library/helpers/validators/serverValidation";
import moment from "moment";
import {clearState, createSchedules} from "../../../redux/schedules/actions";
import {createPackage, updatePackage} from "../../../redux/packages/actions";

const PackageForm = () => {
    const {single_package, formLoading, success, error, loading, pagination} = useSelector(state => state.Packages)
    const {modalVisibility} = useSelector(state => state.rootModal)
    const { colStyle } = basicStyle;
    const { TextArea } = Input;
    const {Text} = Typography
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { Option } = Select;

    useEffect(() => {
        form.resetFields()
    }, [modalVisibility])

    const onFinish = (values) => {
        const formData = {
            title: values.title,
            description: (values.description) ? values.description : '' ,
            expire_in_months: values.expire_in_months,
            price: (values.price) ? values.price : 0
        }
        if (Object.keys(single_package).length > 0){
            dispatch(updatePackage(formData, single_package.id))
        }else{
            dispatch(createPackage(formData))
        }
    }

    if (error) {
        if (error.code === 422) {
            form.setFields(validateFromServer(error));
            dispatch(clearState());
        }
    }

    return (
        <Spin spinning={formLoading}>
            <Form
                id="package_form"
                name="package_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                onFinish={onFinish}
                initialValues={single_package}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter a title!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <TextArea rows={3} style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Expire in months"
                                name="expire_in_months"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter number of months!",
                                    },
                                ]}
                            >
                                <InputNumber min={1} style={{width: "100%"}} step="1"/>
                            </Form.Item>
                        </Col>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Price"
                                name="price"
                            >
                                <InputNumber min={0} style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}


export default PackageForm;
