import React, {useEffect} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin} from "antd";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {createCompanyTrail, updateCompanyTrail} from "../../../../../redux/company/actions";


const TrailForm = ({company_id}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {subscription, formLoading, error, products, packages} = useSelector(state => state.Company)
    const { Option } = Select;
    const {TextArea} = Input

    useEffect(() => {
        form.resetFields()
    }, [subscription])

    const onFinish = (data) => {
        if (company_id) {
            const formData = {
                company_id: company_id,
                company_view: data.company_view,
                product_view: data.product_view,
                start_period: (data.start_period) ? moment(data.start_period).format('YYYY-MM-DD HH:mm:ss') : (subscription.start_period) ?  moment(subscription.start_period).format('YYYY-MM-DD HH:mm:ss') :  moment().format('YYYY-MM-DD HH:mm:ss'),
                end_period: (data.end_period) ? moment(data.end_period).format('YYYY-MM-DD HH:mm:ss') : (subscription.end_period) ?  moment(subscription.end_period).format('YYYY-MM-DD HH:mm:ss') :  moment().format('YYYY-MM-DD HH:mm:ss')
            }
            if (Object.keys(subscription).length > 0) {
                dispatch(updateCompanyTrail(subscription.id, formData))
            } else {
                dispatch(createCompanyTrail(formData))
            }
        }
    }
    return (
        <Spin spinning={formLoading}>
            <Form
                id="company_trail_form"
                name="company_trail_form"
                layout="vertical"
                form={form}
                style={{ width: "100%" }}
                onFinish={onFinish}
                initialValues={{
                    company_view: subscription.company_view_all,
                    product_view: subscription.product_view_all,
                    start_period: (Object.keys(subscription).length > 0) ? moment(subscription.start_period):null,
                    end_period: (Object.keys(subscription).length > 0) ? moment(subscription.end_period):null
                }}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{ marginBottom: 0}}>
                        <Form.Item
                            label="Start Period"
                            name="start_period"
                            rules={[{
                                required: true,
                                message: 'Please input start period!'
                            },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} showTime defaultValue={(Object.keys(subscription).length > 0) && moment(subscription?.start_period)} placeholder={'Start Period'}/>
                        </Form.Item>
                        <Form.Item
                            label="End Period"
                            name="end_period"
                            rules={[{
                                required: true,
                                message: 'Please input end period!'
                            },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} showTime defaultValue={(Object.keys(subscription).length > 0) && moment(subscription?.end_period)} placeholder={'Start Period'}/>
                        </Form.Item>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Number of company profiles to view"
                                    name="company_view"
                                    rules={[{
                                        required: true,
                                        message: 'Please input a number!'
                                    }]}
                                >
                                    <InputNumber min={0} style={{width: "100%"}} step="1" placeholder={'Number of company profiles to view'}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Number of product profiles to view"
                                        name="product_view"
                                    rules={[{
                                        required: true,
                                        message: 'Please input a number'
                                    }]}
                                >
                                    <InputNumber min={0} style={{width: "100%"}} step="1" placeholder={'Number of product profiles to view'}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default TrailForm
