import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  SET_AUTH_STATE,
  SET_AUTH_SUCCESS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_VALIDATION_ERROR,
  CLEAR_AUTH_STATE,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  RESEND_EMAIL,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  SUBTRACT_PRODUCTS_TRAIL_VIEW,
  SUBTRACT_COMPANY_TRAIL_VIEW
} from "./actionTypes";

const initState = {
  loginSuccess: '',
  loginError: '',
  loading: false,
  validationError: "",
  id: '',
  name: '',
  email: '',
  permissions: [],
  subscriptions: [],
  role: '',
  isTrail: false,
  trailViews: {
    companyView: 0,
    productView: 0,
    companyViewAll: 0,
    productViewAll: 0
  },
  token: null,
  routeLoading: false
};

 const Auth =(state = initState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token.access_token,
        id: action.payload.user.id,
        name: action.payload.user.name,
        email: action.payload.user.email,
        permissions: action.payload.user.permissions,
        role: action.payload.user.role,
        subscriptions: action.payload.user.subscriptions,
        isTrail: action.payload.user.is_trail,
        trailViews: {
          companyView: action.payload.user.trail_views.company_view,
          productView: action.payload.user.trail_views.product_view,
          companyViewAll: action.payload.user.trail_views.company_view_all,
          productViewAll: action.payload.user.trail_views.product_view_all
        },
        loginSuccess: "Login Success"
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        loginError: action.payload,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        loginSuccess: "User Successfully Register"
      };
    case REGISTER_ERROR:
      return{
        ...state,
        loading: false,
        loginSuccess: "",
        loginError: action.payload
      };
    case REGISTER_VALIDATION_ERROR:
      return{
        ...state,
        loading: false,
        loginSuccess: "",
        validationError: action.payload
      };
    case LOGOUT:
      return initState;
    case SET_AUTH_STATE:
      return {
        ...state,
        routeLoading: true
      };
    case SET_AUTH_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        id: action.payload.user.id,
        name: action.payload.user.name,
        email: action.payload.user.email,
        permissions: action.payload.user.permissions,
        role: action.payload.user.role,
        subscriptions: action.payload.user.subscriptions,
        isTrail: action.payload.user.is_trail,
        trailViews:{
          companyView: action.payload.user.trail_views.company_view,
          productView: action.payload.user.trail_views.product_view,
          companyViewAll: action.payload.user.trail_views.company_view_all,
          productViewAll: action.payload.user.trail_views.product_view_all
        },
        routeLoading: false
      };
    case VERIFY_EMAIL:
      return {
        ...state,
        loading: true,
      };

    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loginSuccess: "User Email verified successfully"
      };

    case VERIFY_EMAIL_ERROR:
      return{
        ...state,
        loading: false,
        loginSuccess: "",
        loginError: action.payload
      };
    case RESEND_EMAIL:
      return {
        ...state,
        loading: true,
      };

    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loginSuccess: "Verification email was resend successfully"
      };
    case RESEND_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        loginSuccess: "",
        loginError: action.payload
      }
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loginSuccess: "Password was changed successfully"
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        loginSuccess: "",
        loginError: action.payload
      }
    case FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loginSuccess: "Please check your email for reset password link"
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        loginSuccess: "",
        loginError: action.payload
      }
    case PASSWORD_RESET:
      return {
        ...state,
        loading: true,
      };
    case SUBTRACT_PRODUCTS_TRAIL_VIEW:
      return {
        ...state,
        trailViews: {
          companyView: state.trailViews.companyView,
          productView: state.trailViews.productView - 1,
          companyViewAll: state.trailViews.companyViewAll,
          productViewAll: state.trailViews.productViewAll
        }
      };
    case SUBTRACT_COMPANY_TRAIL_VIEW:
      return {
        ...state,
        trailViews: {
          companyView: state.trailViews.companyView - 1,
          productView: state.trailViews.productView,
          companyViewAll: state.trailViews.companyViewAll,
          productViewAll: state.trailViews.productViewAll
        }
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        loginSuccess: "Your password was successfully reset"
      };
    case PASSWORD_RESET_ERROR:
      return {
        ...state,
        loading: false,
        loginSuccess: "",
        loginError: action.payload
      }
    case CLEAR_AUTH_STATE:
      return {
        ...state,
        loginSuccess: '',
        loginError: '',
        loading: false,
        validationError: '',
        routeLoading: false
      };
    default:
      return state;
  }
};

export default Auth
