export const GET_COMPANY_VERIFIED_PRODUCTS = "GET_COMPANY_VERIFIED_PRODUCTS";
export const GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS = "GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS";

export const GET_COMPANY_OTHER_PRODUCTS = "GET_COMPANY_OTHER_PRODUCTS";
export const GET_COMPANY_OTHER_PRODUCTS_SUCCESS = "GET_COMPANY_OTHER_PRODUCTS_SUCCESS";

export const GET_COMPANY_PROFILE_DATA = "GET_COMPANY_PROFILE_DATA";
export const GET_COMPANY_PROFILE_DATA_SUCCESS = "GET_COMPANY_PROFILE_DATA_SUCCESS";

export const EXPORT_COMPANY_PROFILE_EXCEL_REPORT = "EXPORT_COMPANY_PROFILE_EXCEL_REPORT";
export const EXPORT_COMPANY_PROFILE_EXCEL_REPORT_SUCCESS = "EXPORT_COMPANY_PROFILE_EXCEL_REPORT_SUCCESS";

export const EXPORT_COMPANY_PROFILE_PDF_REPORT = "EXPORT_COMPANY_PROFILE_PDF_REPORT";
export const EXPORT_COMPANY_PROFILE_PDF_REPORT_SUCCESS = "EXPORT_COMPANY_PROFILE_PDF_REPORT_SUCCESS";

export const SET_PAGINATION_COMPANY_VERIFIED_PRODUCERS = "SET_PAGINATION_COMPANY_VERIFIED_PRODUCERS";
export const SET_PAGINATION_COMPANY_OTHER_PRODUCERS = "SET_PAGINATION_COMPANY_OTHER_PRODUCERS";

export const SET_COMPANY_PROFILE_DATA = "SET_COMPANY_PROFILE_DATA";

export const GET_COMPANY_PROFILE_DATA_ERROR = "GET_COMPANY_PROFILE_DATA_ERROR";
export const CLEAR_COMPANY_PROFILE_STATE = "CLEAR_COMPANY_PROFILE_STATE";
