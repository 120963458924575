import {
    SEARCH_COMPANIES,
    SEARCH_COMPANIES_SUCCESS,
    SEARCH_COMPANIES_FAIL,
    SET_COMPANY_SEARCH_QUERY,
    GET_PAGINATION_SEARCH_COMPANIES,
    CLEAR_SEARCH_COMPANIES_STATE
} from "./actionTypes";

export const searchProducerCompanies = (params) => ({
    type: SEARCH_COMPANIES,
    filters: {...params}
});

export const searchProducerCompaniesSuccess = activeIngredients => ({
    type: SEARCH_COMPANIES_SUCCESS,
    payload: activeIngredients,
});

export const searchProducerCompaniesFail = (error) => ({
    type: SEARCH_COMPANIES_FAIL,
    payload: error,
});

export const setCompanySearchQuery = (query) => ({
    type: SET_COMPANY_SEARCH_QUERY,
    payload: query,
});

export const setPagination = pagination => ({
    type: GET_PAGINATION_SEARCH_COMPANIES,
    payload: pagination,
});

export const clearState = (state) => ({
    type: CLEAR_SEARCH_COMPANIES_STATE,
    payload: state
});
