import {
    GET_EXHIBITION_LOG,
    GET_EXHIBITION_LOG_SUCCESS,
    GET_PAGINATION_EXHIBITION_LOG,
    GET_VISITORS_EXHIBITION_LOG,
    GET_VISITORS_EXHIBITION_LOG_SUCCESS,
    GET_PAGINATION_VISITORS_EXHIBITION_LOG,
    EXPORT_EXHIBITION_LOG,
    EXPORT_EXHIBITION_LOG_SUCCESS,
    GET_EXHIBITION_LOG_DATA_ERROR,
    CLEAR_EXHIBITION_LOG_DATA_STATE
} from "./actionTypes";
import {
    EXPORT_AI_PROFILE_EXCEL_REPORT,
    EXPORT_AI_PROFILE_EXCEL_REPORT_SUCCESS
} from "../main/activeIngredientProfile/actionTypes";

export const getExhibitionLog = (params) => ({
    type: GET_EXHIBITION_LOG,
    filters: {...params}
});

export const getExhibitionLogSuccess = activeIngredients => ({
    type: GET_EXHIBITION_LOG_SUCCESS,
    payload: activeIngredients,
});

export const setExhibitionLogPagination = pagination => ({
    type: GET_PAGINATION_EXHIBITION_LOG,
    payload: pagination,
});

export const getExhibitionVisitorsLog = (params) => ({
    type: GET_VISITORS_EXHIBITION_LOG,
    filters: {...params}
});

export const getExhibitionVisitorsLogSuccess = activeIngredients => ({
    type: GET_VISITORS_EXHIBITION_LOG_SUCCESS,
    payload: activeIngredients,
});

export const setExhibitionVisitorsLogPagination = pagination => ({
    type: GET_PAGINATION_VISITORS_EXHIBITION_LOG,
    payload: pagination,
});

export const exportExhibitionLog= () => ({
    type: EXPORT_EXHIBITION_LOG,
})

export const exportExhibitionLogSuccess = () => ({
    type: EXPORT_EXHIBITION_LOG_SUCCESS,
});


export const dataError = error => ({
    type: GET_EXHIBITION_LOG_DATA_ERROR,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_EXHIBITION_LOG_DATA_STATE,
    payload: state
});
