import {
    GET_COMPANY_VERIFIED_PRODUCTS,
    GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS,
    GET_COMPANY_OTHER_PRODUCTS,
    GET_COMPANY_OTHER_PRODUCTS_SUCCESS,
    GET_COMPANY_PROFILE_DATA,
    GET_COMPANY_PROFILE_DATA_SUCCESS,
    SET_PAGINATION_COMPANY_VERIFIED_PRODUCERS,
    SET_PAGINATION_COMPANY_OTHER_PRODUCERS,
    SET_COMPANY_PROFILE_DATA,
    GET_COMPANY_PROFILE_DATA_ERROR,
    CLEAR_COMPANY_PROFILE_STATE,
    EXPORT_COMPANY_PROFILE_EXCEL_REPORT,
    EXPORT_COMPANY_PROFILE_EXCEL_REPORT_SUCCESS,
    EXPORT_COMPANY_PROFILE_PDF_REPORT,
    EXPORT_COMPANY_PROFILE_PDF_REPORT_SUCCESS
} from "./actionTypes";

export const getCompanyVerifiedProducts= (company_id, params) => {
    return {
    type: GET_COMPANY_VERIFIED_PRODUCTS,
    payload: { company_id, params }
}};

export const getCompanyVerifiedProductsSuccess = verified_producers => ({
    type: GET_COMPANY_VERIFIED_PRODUCTS_SUCCESS,
    payload: verified_producers,
});

export const getCompanyOtherProducts= (company_id, params) => ({
    type: GET_COMPANY_OTHER_PRODUCTS,
    payload: { company_id, params}
});

export const getCompanyOtherProductsSuccess = related_producers => ({
    type: GET_COMPANY_OTHER_PRODUCTS_SUCCESS,
    payload: related_producers,
});

export const getCompanyProfileData= (company_id) => {
    return {
        type: GET_COMPANY_PROFILE_DATA,
        payload: { company_id }
    }};

export const getCompanyProfileDataSuccess = company => ({
    type: GET_COMPANY_PROFILE_DATA_SUCCESS,
    payload: company,
});


export const exportCompanyProfileExcelProducers= (company_id, params) => ({
    type: EXPORT_COMPANY_PROFILE_EXCEL_REPORT,
    payload: { company_id, params }
})

export const exportCompanyProfileExcelProducersSuccess = () => ({
    type: EXPORT_COMPANY_PROFILE_EXCEL_REPORT_SUCCESS,
});

export const exportCompanyProfilePdfProducers= (company_id, params) => ({
    type: EXPORT_COMPANY_PROFILE_PDF_REPORT,
    payload: { company_id, params }
})

export const exportCompanyProfilePdfProducersSuccess = () => ({
    type: EXPORT_COMPANY_PROFILE_PDF_REPORT_SUCCESS,
});

export const setCompanyProfileData = company => ({
    type: SET_COMPANY_PROFILE_DATA,
    payload: company,
})

export const dataError = error => ({
    type: GET_COMPANY_PROFILE_DATA_ERROR,
    payload: error,
});

export const setCompanyVerifiedProductsPagination = pagination => ({
    type: SET_PAGINATION_COMPANY_VERIFIED_PRODUCERS,
    payload: pagination,
});

export const setCompanyOtherProductsPagination = pagination => ({
    type: SET_PAGINATION_COMPANY_OTHER_PRODUCERS,
    payload: pagination,
});

export const clearState = (state) => ({
    type: CLEAR_COMPANY_PROFILE_STATE,
    payload: state
});
