import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Menu from '@iso/redux/menu/reducer';
import Routes from '@iso/redux/routes/reducer';
import rootModal from '@iso/redux/rootModal/reducer';
import notification from '@iso/redux/notification/reducer';
import Users from "@iso/redux/users/reducer";
import Profile from "@iso/redux/profile/reducer";
import Schedules from "@iso/redux/schedules/reducer";
import Companies from "@iso/redux/companies/reducer";
import Company from "@iso/redux/company/reducer";
import Packages from "@iso/redux/packages/reducer";
import ActiveIngredients from "@iso/redux/main/activeIngredients/reducer";
import ActiveIngredientsProfile from "@iso/redux/main/activeIngredientProfile/reducer";
import ProducersCompanies from "@iso/redux/main/producersCompanies/reducer";
import ProducersCompaniesProfile from "@iso/redux/main/producersCompaniesProfile/reducer";
import ModalInfo from "@iso/redux/main/modalInfo/reducer";
import AppLogs from "@iso/redux/appLogs/reducer";
import ExhibitionLog from "@iso/redux/exhibitionLogs/reducer";

export default combineReducers({
  App,
  LanguageSwitcher,
  ThemeSwitcher,
  Auth,
  Menu,
  Routes,
  rootModal,
  notification,
  Users,
  Profile,
  Schedules,
  Companies,
  Company,
  Packages,
  ActiveIngredients,
  ActiveIngredientsProfile,
  ProducersCompanies,
  ProducersCompaniesProfile,
  ModalInfo,
  AppLogs,
  ExhibitionLog
});
