import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_COMPANY_PRODUCTS_MODAL,
    GET_COMPANY_RCD_DETAILS_MODAL
} from "./actionTypes";


import {
    getCompanyProductsModalSuccess,
    setCompanyProductsModalPagination,
    getCompanyRcdDetailsModalSuccess,
    setCompanyRcdDetailsModalPagination,
    dataErrorModal
} from "./actions";

import {
    producersCompaniesProductsRequest,
    producersCompaniesRcdsRequest
} from '../../../library/http/backendHelpers'


function* getCompanyProductsModalSaga({ payload: { company_id, params}}) {
    try {
        const response = yield call(producersCompaniesProductsRequest, company_id, params);
        yield put(getCompanyProductsModalSuccess(response.data));
        yield put(setCompanyProductsModalPagination(response.meta))
    }catch (error) {
        yield put(dataErrorModal(error))
    }
}

function* getCompanyRcdDetailsModalSaga({ payload: { company_fm_id, type, params }}) {
    try {
        const response = yield call(producersCompaniesRcdsRequest, company_fm_id, type, params);
        yield put(getCompanyRcdDetailsModalSuccess(response.data));
        yield put(setCompanyRcdDetailsModalPagination(response.meta))
    }catch (error) {
        yield put(dataErrorModal(error))
    }
}

function* modalInfoSaga() {
    yield takeEvery(GET_COMPANY_PRODUCTS_MODAL, getCompanyProductsModalSaga)
    yield takeEvery(GET_COMPANY_RCD_DETAILS_MODAL, getCompanyRcdDetailsModalSaga)
}

export default modalInfoSaga
