import {
    GET_COMPANY_PRODUCTS_MODAL,
    GET_COMPANY_PRODUCTS_MODAL_SUCCESS,
    GET_MODAL_INFO_DATA_ERROR,
    CLEAR_MODAL_INFO_PROFILE_STATE,
    SET_PAGINATION_COMPANY_PRODUCTS_MODAL,
    GET_COMPANY_RCD_DETAILS_MODAL,
    GET_COMPANY_RCD_DETAILS_MODAL_SUCCESS,
    SET_PAGINATION_RCD_DETAILS_MODAL,
} from "./actionTypes";

const initialState = {
    company_products: [],
    company_rcd_details: [],
    company_products_loading: false,
    company_rcd_details_loading: false,
    company_products_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    company_rcd_details_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    success: '',
    error: '',
};

const ModalInfo = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY_PRODUCTS_MODAL:
            return {
                ...state,
                company_products_loading: true
            };
        case GET_COMPANY_PRODUCTS_MODAL_SUCCESS:
            return {
                ...state,
                company_products: action.payload,
                company_products_loading: false
            };
        case SET_PAGINATION_COMPANY_PRODUCTS_MODAL:
            return {
                ...state,
                company_products_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case GET_COMPANY_RCD_DETAILS_MODAL:
            return {
                ...state,
                company_rcd_details_loading: true
            };
        case GET_COMPANY_RCD_DETAILS_MODAL_SUCCESS:
            return {
                ...state,
                company_rcd_details: action.payload,
                company_rcd_details_loading: false
            };
        case SET_PAGINATION_RCD_DETAILS_MODAL:
            return {
                ...state,
                company_rcd_details_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            }
        case GET_MODAL_INFO_DATA_ERROR:
            return {
                ...state,
                company_products_loading: false,
                success: '',
                error: '',
                company_products_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                }
            };
        case CLEAR_MODAL_INFO_PROFILE_STATE:
            return {
                ...state,
                company_products: [],
                company_rcd_details: [],
                company_products_loading: false,
                company_rcd_details_loading: false,
                company_products_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                company_rcd_details_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                success: '',
                error: '',
            };
        default:
            return state;
    }
}

export default ModalInfo;
