export const GET_COMPANY_PRODUCTS_MODAL = "GET_COMPANY_PRODUCTS_MODAL";
export const GET_COMPANY_PRODUCTS_MODAL_SUCCESS = "GET_COMPANY_PRODUCTS_MODAL_SUCCESS";

export const GET_COMPANY_RCD_DETAILS_MODAL = "GET_COMPANY_RCD_DETAILS_MODAL";
export const GET_COMPANY_RCD_DETAILS_MODAL_SUCCESS = "GET_COMPANY_RCD_DETAILS_MODAL_SUCCESS";

export const SET_PAGINATION_COMPANY_PRODUCTS_MODAL = "SET_PAGINATION_COMPANY_PRODUCTS_MODAL";
export const SET_PAGINATION_RCD_DETAILS_MODAL = "SET_PAGINATION_RCD_DETAILS_MODAL";

export const GET_MODAL_INFO_DATA_ERROR = "GET_MODAL_INFO_DATA_ERROR";
export const CLEAR_MODAL_INFO_PROFILE_STATE = "CLEAR_MODAL_INFO_PROFILE_STATE";
