import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_PACKAGES,
    CREATE_PACKAGES,
    UPDATE_PACKAGES,
    DELETE_PACKAGES
} from "./actionTypes";
import {
    getPackagesSuccess,
    createPackageSuccess,
    createPackageFail,
    updatePackageSuccess,
    updatePackageFail,
    deletePackageSuccess,
    dataError,
    setPagination
} from "./actions";

import {
    getSubscriptionsPackage,
    createSubscriptionsPackage,
    updateSubscriptionsPackage,
    deleteSubscriptionsPackage
} from '../../library/http/backendHelpers'

function* fetchPackages(params) {
    try {
        const response = yield call(getSubscriptionsPackage, params.filters);
        yield put(setPagination(response.meta))
        yield put(getPackagesSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* createPackage({ payload: packages }) {
    try {
        const response = yield call(createSubscriptionsPackage, packages);
        yield put(createPackageSuccess(response.data));
    }catch (error) {
        yield put(createPackageFail(error))
    }
}

function* editPackage({payload: {packages, packages_id} }) {
    try {
        const response = yield call(updateSubscriptionsPackage, packages, packages_id);
        yield put(updatePackageSuccess(response.data));
    }catch (error) {
        yield put(updatePackageFail(error))
    }
}

function* deletePackage({ payload: {packages_id}}) {
    try {
        const response = yield call(deleteSubscriptionsPackage, packages_id);
        yield put(deletePackageSuccess(packages_id));
    }catch (error) {
        yield put(dataError(error))
    }
}


function* packagesSaga() {
    yield takeEvery(GET_PACKAGES, fetchPackages);
    yield takeEvery(CREATE_PACKAGES, createPackage);
    yield takeEvery(UPDATE_PACKAGES, editPackage);
    yield takeEvery(DELETE_PACKAGES, deletePackage);
}

export default packagesSaga
