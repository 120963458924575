import {
    GET_COMPANY,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_USERS,
    GET_COMPANY_USERS_SUCCESS,
    GET_COMPANY_SUBSCRIPTIONS,
    GET_COMPANY_SUBSCRIPTIONS_SUCCESS,
    SET_PAGINATION_COMPANY_SUBSCRIPTIONS,
    SET_PAGINATION_COMPANY_USERS,
    GET_COMPANY_DATA_ERROR,
    CLEAR_COMPANY_STATE,
    SET_COMPANY_USERS,
    EDIT_COMPANY_VIEW_SUCCESS,
    CREATE_COMPANY_USERS,
    CREATE_COMPANY_USERS_SUCCESS,
    CREATE_COMPANY_USERS_FAIL,
    UPDATE_COMPANY_USERS,
    UPDATE_COMPANY_USERS_SUCCESS,
    UPDATE_COMPANY_USERS_FAIL,
    RESET_COMPANY_USERS_PASSWORD,
    RESET_COMPANY_USERS_PASSWORD_SUCCESS,
    DELETE_COMPANY_USER,
    DELETE_COMPANY_USER_SUCCESS,
    SET_COMPANY_SUBSCRIPTIONS,
    GET_SUBSCRIPTIONS_PRODUCTS,
    GET_SUBSCRIPTIONS_PRODUCTS_SUCCESS,
    GET_SUBSCRIPTIONS_PACKAGES,
    GET_SUBSCRIPTIONS_PACKAGES_SUCCESS,
    CREATE_COMPANY_SUBSCRIPTIONS,
    CREATE_COMPANY_SUBSCRIPTIONS_SUCCESS,
    CREATE_COMPANY_SUBSCRIPTIONS_FAIL,
    UPDATE_COMPANY_SUBSCRIPTIONS,
    UPDATE_COMPANY_SUBSCRIPTIONS_SUCCESS,
    UPDATE_COMPANY_SUBSCRIPTIONS_FAIL,
    UPDATE_COMPANY_META,
    RENEW_COMPANY_SUBSCRIPTIONS,
    RENEW_COMPANY_SUBSCRIPTIONS_SUCCESS,
    RENEW_COMPANY_SUBSCRIPTIONS_FAIL,
    CANCEL_COMPANY_SUBSCRIPTIONS,
    CANCEL_COMPANY_SUBSCRIPTIONS_SUCCESS,
    CANCEL_COMPANY_SUBSCRIPTIONS_FAIL,
    SENT_COMPANY_USER_VERIFICATION,
    SENT_COMPANY_USER_VERIFICATION_SUCCESS,
    SENT_COMPANY_USER_VERIFICATION_FAIL,
    SET_NEW_COMPANY_USER_PASSWORD,
    SET_NEW_COMPANY_USER_PASSWORD_SUCCESS,
    SET_NEW_COMPANY_USER_PASSWORD_FAIL,
    VERIFY_COMPANY_USER,
    VERIFY_COMPANY_USER_SUCCESS,
    CREATE_COMPANY_TRAIL,
    CREATE_COMPANY_TRAIL_SUCCESS,
    CREATE_COMPANY_TRAIL_FAIL,
    UPDATE_COMPANY_TRAIL,
    UPDATE_COMPANY_TRAIL_SUCCESS,
    UPDATE_COMPANY_TRAIL_FAIL,
    DELETE_COMPANY_SUBSCRIPTIONS,
    DELETE_COMPANY_SUBSCRIPTIONS_SUCCESS
} from "./actionTypes";

const initialState = {
    company: {},
    users: [],
    user: {},

    subscriptions: [],
    products: [],
    packages: [],
    subscription: {},

    userPagination: {current: 1, pageSize: 0, total: 0},
    subscriptionsPagination: {current: 1, pageSize: 0, total: 0},

    loading: false,
    formLoading: false,
    userLoading: false,
    subscriptionsLoading: false,
    success: '',
    error: '',
};

const Company = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY:
            return {
                ...state,
                loading: true
            };
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload,
                loading: false
            }
            //users
        case GET_COMPANY_USERS:
            return {
                ...state,
                userLoading: true
            };
        case GET_COMPANY_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                userLoading: false
            }
        case SET_COMPANY_USERS:
            return {
                ...state,
                user: action.payload
            };
        case EDIT_COMPANY_VIEW_SUCCESS:
            return {
                ...state,
                company: action.payload,
                success: "Company was successfully edited",
            };
        case CREATE_COMPANY_USERS:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_COMPANY_USERS_SUCCESS:
            return {
                ...state,
                success: 'User was successfully created',
                users: [action.payload, ...state.users],
                formLoading: false
            };
        case CREATE_COMPANY_USERS_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case UPDATE_COMPANY_USERS:
            return {
                ...state,
                formLoading: true,
            };
        case UPDATE_COMPANY_USERS_SUCCESS:
            return {
                ...state,
                users: state.users.map((user) => user.id === action.payload.id ? action.payload : user),
                success: "User was successfully edited",
                formLoading: false,
            };
        case UPDATE_COMPANY_USERS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case SENT_COMPANY_USER_VERIFICATION:
            return {
                ...state,
                userLoading: true,
            };
        case SENT_COMPANY_USER_VERIFICATION_SUCCESS:
            return {
                ...state,
                users: state.users.map((user) => user.id === action.payload.id ? action.payload : user),
                success: "Verification email was sent successfully",
                userLoading: false,
            };
        case SENT_COMPANY_USER_VERIFICATION_FAIL:
            return {
                ...state,
                error: action.payload,
                userLoading: false,
            };
        case RESET_COMPANY_USERS_PASSWORD:
            return {
                ...state,
                formLoading: true
            };
        case RESET_COMPANY_USERS_PASSWORD_SUCCESS:
            return {
                ...state,
                success: "User password was successfully reset",
                formLoading: false
            };
        case DELETE_COMPANY_USER:
            return {
                ...state,
                loading: true
            };
        case DELETE_COMPANY_USER_SUCCESS:
            return {
                ...state,
                users: state.users.filter(user => user.id !== action.payload),
                success: "User was successfully deleted",
                loading: false
            };
            //subscriptions
        case GET_COMPANY_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptionsLoading: true
            };
        case GET_COMPANY_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: action.payload,
                subscriptionsLoading: false
            };
        case SET_COMPANY_SUBSCRIPTIONS:
            return {
                ...state,
                subscription: action.payload
            };
        case GET_SUBSCRIPTIONS_PRODUCTS:
            return {
                ...state,
                formLoading: true
            };
        case GET_SUBSCRIPTIONS_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload,
                formLoading: false
            }
        case CREATE_COMPANY_SUBSCRIPTIONS:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_COMPANY_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                success: 'Subscription was successfully created',
                subscriptions: [action.payload, ...state.subscriptions],
                formLoading: false
            };
        case CREATE_COMPANY_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case UPDATE_COMPANY_SUBSCRIPTIONS:
            return {
                ...state,
                formLoading: true,
            };
        case UPDATE_COMPANY_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: state.subscriptions.map((subscription) => subscription.id === action.payload.id ? action.payload : subscription),
                success: "Subscription was successfully edited",
                formLoading: false,
            };
        case UPDATE_COMPANY_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case CREATE_COMPANY_TRAIL:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_COMPANY_TRAIL_SUCCESS:
            return {
                ...state,
                success: 'Trail was successfully created',
                subscriptions: [action.payload, ...state.subscriptions],
                formLoading: false
            };
        case CREATE_COMPANY_TRAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case UPDATE_COMPANY_TRAIL:
            return {
                ...state,
                formLoading: true,
            };
        case UPDATE_COMPANY_TRAIL_SUCCESS:
            return {
                ...state,
                subscriptions: state.subscriptions.map((subscription) => subscription.id === action.payload.id ? action.payload : subscription),
                success: "Trail was successfully edited",
                formLoading: false,
            };
        case UPDATE_COMPANY_TRAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case DELETE_COMPANY_SUBSCRIPTIONS:
            return {
                ...state,
                loading: true
            };
        case DELETE_COMPANY_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: state.subscriptions.filter(subscription => subscription.id !== action.payload),
                success: "Subscription was successfully deleted",
                loading: false
            };
        case RENEW_COMPANY_SUBSCRIPTIONS:
            return {
                ...state,
                loading: true,
                subscriptionsLoading: true
            };
        case RENEW_COMPANY_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: state.subscriptions.map((subscription) => subscription.id === action.payload.id ? action.payload : subscription),
                success: "Subscription was successfully renewed",
                loading: false,
                subscriptionsLoading: true
            };
        case RENEW_COMPANY_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case CANCEL_COMPANY_SUBSCRIPTIONS:
            return {
                ...state,
                loading: true,
                subscriptionsLoading: true
            };
        case CANCEL_COMPANY_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: state.subscriptions.map((subscription) => subscription.id === action.payload.id ? action.payload : subscription),
                success: "Subscription was successfully canceled",
                loading: false,
                subscriptionsLoading: true
            };
        case CANCEL_COMPANY_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                subscriptionsLoading: true
            };
        case UPDATE_COMPANY_META:
            return {
                ...state,
                company: action.payload,
            };
        case GET_SUBSCRIPTIONS_PACKAGES:
            return {
                ...state,
                formLoading: true
            };
        case GET_SUBSCRIPTIONS_PACKAGES_SUCCESS:
            return {
                ...state,
                packages: action.payload,
                formLoading: false
            }
        case SET_PAGINATION_COMPANY_USERS:
            return {
                ...state,
                userPagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };

        case SET_PAGINATION_COMPANY_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptionsPagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case VERIFY_COMPANY_USER:
            return {
                ...state,
                loading: true
            };
        case VERIFY_COMPANY_USER_SUCCESS:
            return {
                ...state,
                success: "User was verify successfully",
                users: state.users.map((user) => user.id === action.payload.id ? action.payload : user),
                loading: false
            };

        case SET_NEW_COMPANY_USER_PASSWORD:
            return {
                ...state,
                formLoading: true,
            };
        case SET_NEW_COMPANY_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                success: "User Password was successfully changed",
                formLoading: false,
            };
        case SET_NEW_COMPANY_USER_PASSWORD_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case GET_COMPANY_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                userLoading: false,
                subscriptionsLoading: false
            };
        case CLEAR_COMPANY_STATE:
            return {
                ...state,
                loading: false,
                formLoading: false,
                userLoading: false,
                subscriptionsLoading: false,
                success: '',
                error: '',
            };
        default:
            return state;
    }
}

export default Company;
