import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_COMPANY_VERIFIED_PRODUCTS,
    GET_COMPANY_OTHER_PRODUCTS,
    GET_COMPANY_PROFILE_DATA,
    EXPORT_COMPANY_PROFILE_EXCEL_REPORT,
    EXPORT_COMPANY_PROFILE_PDF_REPORT,
} from "./actionTypes";

import {
    getCompanyVerifiedProductsSuccess,
    getCompanyOtherProductsSuccess,
    getCompanyProfileDataSuccess,
    dataError,
    setCompanyVerifiedProductsPagination,
    setCompanyOtherProductsPagination,
    exportCompanyProfilePdfProducersSuccess,
    exportCompanyProfileExcelProducersSuccess,
} from "./actions";

import {
    producersCompaniesProfileRequest,
    companyProfileDataRequest,
    producersCompaniesOtherRequest,
    exportCompanyProfileExcelProducersRequest,
    exportCompanyProfilePdfProducersRequest
} from '../../../library/http/backendHelpers'
import moment from "moment";
import {
    exportAiProfileExcelProducersSuccess,
    exportAiProfilePdfProducersSuccess
} from "../activeIngredientProfile/actions";


function* getCompaniesVerifiedProducersSaga({ payload: { company_id, params }}) {
    try {
        const response = yield call(producersCompaniesProfileRequest, company_id, params);
        yield put(getCompanyVerifiedProductsSuccess(response.data));
        yield put(setCompanyVerifiedProductsPagination(response.meta));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getCompaniesOtherProducersSaga({ payload: { company_id, params }}) {
    try {
        const response = yield call(producersCompaniesOtherRequest, company_id, params);
        yield put(getCompanyOtherProductsSuccess(response.data));
        yield put(setCompanyOtherProductsPagination(response.meta));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getCompanyProfileDataSga({ payload: { company_id}}) {
    try {
        const response = yield call(companyProfileDataRequest, company_id);
        yield put(getCompanyProfileDataSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* exportCompanyProfileExcelProducersSaga({ payload: { company_id, params}}) {
    try {
        const filename  = 'api-producer-producer-list-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportCompanyProfileExcelProducersRequest, company_id, params, filename);
        yield put(exportCompanyProfileExcelProducersSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}

function* exportCompanyProfilePdfProducersSaga({ payload: { company_id, params}}) {
    try {
        const filename  = 'api-producer-producer-list-'+ moment().format('Y-m-dH H:mm:ss') + '.pdf';
        const response = yield call(exportCompanyProfilePdfProducersRequest, company_id, params, filename);
        yield put(exportCompanyProfilePdfProducersSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}

function* producersCompaniesProfileSaga() {
    yield takeEvery(GET_COMPANY_VERIFIED_PRODUCTS, getCompaniesVerifiedProducersSaga)
    yield takeEvery(GET_COMPANY_OTHER_PRODUCTS, getCompaniesOtherProducersSaga)
    yield takeEvery(GET_COMPANY_PROFILE_DATA, getCompanyProfileDataSga)
    yield takeEvery(EXPORT_COMPANY_PROFILE_EXCEL_REPORT, exportCompanyProfileExcelProducersSaga)
    yield takeEvery(EXPORT_COMPANY_PROFILE_PDF_REPORT, exportCompanyProfilePdfProducersSaga)
}

export default producersCompaniesProfileSaga
