export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";

export const CREATE_PACKAGES = "CREATE_PACKAGES";
export const CREATE_PACKAGES_SUCCESS = "CREATE_PACKAGES_SUCCESS";
export const CREATE_PACKAGES_FAIL = "CREATE_PACKAGES_FAIL";

export const UPDATE_PACKAGES = "UPDATE_PACKAGES";
export const UPDATE_PACKAGES_SUCCESS = "UPDATE_PACKAGES_SUCCESS";
export const UPDATE_PACKAGES_FAIL = "UPDATE_PACKAGES_FAIL";

export const DELETE_PACKAGES = "DELETE_PACKAGES"
export const DELETE_PACKAGES_SUCCESS = "DELETE_PACKAGES_SUCCESS"

export const SET_PACKAGE = "SET_PACKAGE";

export const GET_PAGINATION_PACKAGES = "GET_PAGINATION_PACKAGES"

export const GET_PACKAGES_DATA_ERROR = "GET_PACKAGES_DATA_ERROR";
export const CLEAR_PACKAGES_STATE = "CLEAR_PACKAGES_STATE";
