export const GET_COMMANDS = 'GET_COMMANDS';
export const GET_COMMANDS_SUCCESS = 'GET_COMMANDS_SUCCESS';
export const GET_COMMANDS_ERROR = 'GET_COMMANDS_ERROR';

export const RUN_COMMAND = 'RUN_COMMAND';
export const RUN_COMMAND_SUCCESS = 'RUN_COMMAND_SUCCESS';
export const RUN_COMMAND_ERROR = 'RUN_COMMAND_ERROR';

export const SET_LOADING = "SET_LOADING"
export const RESET_LOADING = "RESET_LOADING"
export const GET_SCHEDULES_DATA_ERROR = "GET_SCHEDULES_DATA_ERROR";
export const CLEAR_SCHEDULES_STATE = "CLEAR_SCHEDULES_STATE";
