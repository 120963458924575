import {
    GET_COMPANY_PRODUCTS_MODAL,
    GET_COMPANY_PRODUCTS_MODAL_SUCCESS,
    GET_MODAL_INFO_DATA_ERROR,
    CLEAR_MODAL_INFO_PROFILE_STATE,
    SET_PAGINATION_COMPANY_PRODUCTS_MODAL,
    GET_COMPANY_RCD_DETAILS_MODAL,
    GET_COMPANY_RCD_DETAILS_MODAL_SUCCESS,
    SET_PAGINATION_RCD_DETAILS_MODAL
} from "./actionTypes";

export const getCompanyProductsModal = (company_id, params) => ({
    type: GET_COMPANY_PRODUCTS_MODAL,
    payload: { company_id, params }
});

export const getCompanyProductsModalSuccess = products => ({
    type: GET_COMPANY_PRODUCTS_MODAL_SUCCESS,
    payload: products,
});

export const getCompanyRcdDetailsModal = (company_fm_id, type, params) => ({
    type: GET_COMPANY_RCD_DETAILS_MODAL,
    payload: { company_fm_id, type, params }
});

export const getCompanyRcdDetailsModalSuccess = rcds => ({
    type: GET_COMPANY_RCD_DETAILS_MODAL_SUCCESS,
    payload: rcds,
});

export const setCompanyProductsModalPagination = pagination => ({
    type: SET_PAGINATION_COMPANY_PRODUCTS_MODAL,
    payload: pagination,
});

export const setCompanyRcdDetailsModalPagination = pagination => ({
    type: SET_PAGINATION_RCD_DETAILS_MODAL,
    payload: pagination,
});

export const dataErrorModal = error => ({
    type: GET_MODAL_INFO_DATA_ERROR,
    payload: error,
});

export const clearStateModal = (state) => ({
    type: CLEAR_MODAL_INFO_PROFILE_STATE,
    payload: state
});
