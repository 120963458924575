import {
    GET_COMMANDS,
    GET_COMMANDS_SUCCESS,
    GET_COMMANDS_ERROR,
    RUN_COMMAND,
    RUN_COMMAND_SUCCESS,
    RUN_COMMAND_ERROR,
    SET_LOADING,
    RESET_LOADING,
    GET_SCHEDULES_DATA_ERROR,
    CLEAR_SCHEDULES_STATE,
} from "./actionTypes";

export const getCommands = () => ({
    type: GET_COMMANDS,
});

export const getCommandsSuccess = (data) => ({
    type: GET_COMMANDS_SUCCESS,
    payload: data
});

export const getCommandsError = (error) => ({
    type: GET_COMMANDS_ERROR,
    payload: error,
});

export const runCommand = (command) => ({
    type: RUN_COMMAND,
    payload: {command}
});

export const runCommandSuccess = () => ({
    type: RUN_COMMAND_SUCCESS
});

export const runCommandError = (error) => ({
    type: RUN_COMMAND_ERROR,
    payload: error
});

export const setLoading = (command) => ({
    type: SET_LOADING,
    payload: command,
});

export const resetLoading = (command) => ({
    type: RESET_LOADING,
    payload: command,
});

export const dataError = error => ({
    type: GET_SCHEDULES_DATA_ERROR,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_SCHEDULES_STATE,
    payload: state
});
