import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table, Pagination} from "antd";
import {getCompanyProductsModal} from "../../../redux/main/modalInfo/actions";
import {getAiOtherProducers} from "../../../redux/main/activeIngredientProfile/actions";
import {clearStateModal} from "../../../redux/main/modalInfo/actions";


const ProducersProductsModal = ({company_id}) => {
    const dispatch = useDispatch();
    const {company_products, company_products_loading, company_products_pagination} = useSelector(state => state.ModalInfo)

    useEffect(() => {
        dispatch(clearStateModal())
        dispatch(getCompanyProductsModal(company_id))
    }, [company_id])

    const handleOtherProducerTableChange = (pagination, filters, sorter) => {
        dispatch(getCompanyProductsModal(company_id ,{
            page: pagination.current,
            per_page: pagination.pageSize,
            orderby: sorter.field,
            direction: (sorter.order === "ascend") ? "asc" : "desc",
        }));
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'salt_name',
            sorter: true
        },
        {
            title: 'CAS',
            dataIndex: 'cas',
            sorter: true
        }
    ];
    return (
        <Table
            columns={columns}
            dataSource={company_products}
            loading={company_products_loading}
            pagination={{...company_products_pagination, showSizeChanger: true}}
            onChange={handleOtherProducerTableChange}
            size="small" />
    )
}


export default ProducersProductsModal;
