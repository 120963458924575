import React, {useEffect} from "react";
import {Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getCompanyProductsModal, getCompanyRcdDetailsModal} from "../../../redux/main/modalInfo/actions";


const RcdDetailsModal = ({company_fm_id, product_fm_id, type}) => {
    const dispatch = useDispatch();
    const {company_rcd_details, company_rcd_details_loading, company_rcd_details_pagination} = useSelector(state => state.ModalInfo)
    const columnsArray = {
        cos: [
            {title: 'Certificate Number', dataIndex: 'certificate_number', sorter: false},
            {title: 'Issue Date', dataIndex: 'issue_date', sorter: false,
                render: (text) => {
                    const date = new Date(text);
                    if (!isNaN(date.getTime())) { // Checks if the date is valid
                        return `${String(date.getDate()).padStart(2, '0')} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                    } else {
                        return text;
                    }
                }},
            {title: 'Substance Name', dataIndex: 'substance_name', sorter: false},
            {title: 'Certificate Holder', dataIndex: 'certificate_holder', sorter: false}
        ],
        dmf: [
            {title: 'DMF number', dataIndex: 'dmf_number', sorter: false},
            {title: 'Type', dataIndex: 'type', sorter: false},
            {
                title: 'Submission Date', dataIndex: 'submission_date', sorter: false,
                render: (text) => {
                    if (text) {
                        const parts = text.split('/');
                        const date = new Date(parts[2], parts[1] - 1, parts[0]);
                        if (!isNaN(date.getTime())) { // Checks if the date is valid
                            return `${String(date.getDate()).padStart(2, '0')} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                        } else {
                            return text;
                        }
                    }
                }},
            {title: 'Holder', dataIndex: 'holder', sorter: false},
            {title: 'Subject', dataIndex: 'subject', sorter: false}
        ],
        jdmf: [
            {title: 'Registration number', dataIndex: 'registration_number', sorter: false},
            {title: 'Latest', dataIndex: 'latest', sorter: false,
                render: (text) => {
                    const date = new Date(text);
                    if (!isNaN(date.getTime())) { // Checks if the date is valid
                        return `${String(date.getDate()).padStart(2, '0')} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                    } else {
                        return text;
                    }
                }},
            {title: 'Registrant Name', dataIndex: 'registrant_name', sorter: false},
            {title: 'Item Name', dataIndex: 'item_name', sorter: false}
        ],
        kdmf: [
            {title: 'Registration number', dataIndex: 'registration_number', sorter: false},
            {title: 'Updated', dataIndex: 'updated', sorter: false, render: (text) => {
                    const date = new Date(text);
                    if (!isNaN(date.getTime())) { // Checks if the date is valid
                        return `${String(date.getDate()).padStart(2, '0')} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                    } else {
                        return text;
                    }
                }},
            {title: 'Company', dataIndex: 'company', sorter: false},
            {title: 'Subject', dataIndex: 'subject', sorter: false}
        ],
        vmf: [
            {title: 'Number', dataIndex: 'unique_number', sorter: false},
            {title: 'Status', dataIndex: 'status', sorter: false},
            {title: 'Subject', dataIndex: 'subject', sorter: false},
            {title: 'Holder', dataIndex: 'holder', sorter: false},
        ],
        who: [
            {title: 'Reference number', dataIndex: 'reference_number', sorter: false},
            {title: 'INN', dataIndex: 'inn', sorter: false},
            {title: 'Applicant', dataIndex: 'applicant', sorter: false}
        ],
        cgmp: [
            {title: 'Certificate number', dataIndex: 'certificate_number', sorter: false},
            {title: 'Scope', dataIndex: 'scope', sorter: false},
            {title: 'Company', dataIndex: 'company', sorter: false},
            {title: 'Date of Issue', dataIndex: 'date_of_issue', sorter: false, render: (text) => {
                    const date = new Date(text);
                    if (!isNaN(date.getTime())) { // Checks if the date is valid
                        return `${String(date.getDate()).padStart(2, '0')} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                    } else {
                        return text;
                    }
                }},
        ],
        egmp: [
            {title: 'Certificate number', dataIndex: 'certificate_number', sorter: false},
            {title: 'Site name', dataIndex: 'site_name', sorter: false}
        ],
        fda: [
            {title: 'FEI', dataIndex: 'fei', sorter: false},
            {title: 'Firm name', dataIndex: 'firm_name', sorter: false},
            {title: 'Country', dataIndex: 'country', sorter: false},
            {title: 'Inspection End date', dataIndex: 'inspection_end_date', sorter: false, render: (text) => {
                    const date = new Date(text);
                    if (!isNaN(date.getTime())) { // Checks if the date is valid
                        return `${String(date.getDate()).padStart(2, '0')} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                    } else {
                        return text;
                    }
                }},
            {title: '483 Issued', dataIndex: 'issued_483', sorter: false}
        ],
        igmp: [
            {title: 'Sr No', dataIndex: 'sr_no', sorter: false},
            {title: 'RC No', dataIndex: 'rc_no', sorter: false},
            {title: 'Manufacturer', dataIndex: 'manufacturer', sorter: false},
            {title: 'Issue date', dataIndex: 'issue_date', sorter: false, render: (text) => {
                    const date = new Date(text);
                    if (!isNaN(date.getTime())) { // Checks if the date is valid
                        return `${String(date.getDate()).padStart(2, '0')} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                    } else {
                        return text;
                    }
                }},
        ]
    };

    const handleCompaniesRcdTableChange = (pagination, filters, sorter) => {
        dispatch(getCompanyRcdDetailsModal(company_fm_id, type ,{
            page: pagination.current,
            per_page: pagination.pageSize,
            orderby: sorter.field,
            direction: (sorter.order === "ascend") ? "asc" : "desc",
            fm_product_id: product_fm_id
        }));
    }

    useEffect(() => {
        if (product_fm_id) {
            dispatch(getCompanyRcdDetailsModal(company_fm_id, type, {fm_product_id: product_fm_id}))
        }else{
            dispatch(getCompanyRcdDetailsModal(company_fm_id, type))
        }
    }, [company_fm_id, product_fm_id, type])

    return (
        <Table
            columns={columnsArray[type]}
            dataSource={company_rcd_details}
            loading={company_rcd_details_loading}
            pagination={company_rcd_details_pagination}
            onChange={handleCompaniesRcdTableChange}
            size="small" />
    )
}

export default RcdDetailsModal
