import { call, takeEvery, put } from 'redux-saga/effects';
import {
    EXPORT_EXHIBITION_LOG,
    GET_EXHIBITION_LOG,
    GET_VISITORS_EXHIBITION_LOG
} from "./actionTypes";

import {
    dataError, exportExhibitionLogSuccess,
    getExhibitionLogSuccess, getExhibitionVisitorsLogSuccess,
    setExhibitionLogPagination, setExhibitionVisitorsLogPagination
} from "./actions";

import {
    exportAiProfileExcelProducersRequest, exportExhibitionLogRequest,
    getExhibitionLogRequest,
    getExhibitionVisitorsLogRequest
} from '../../library/http/backendHelpers'
import moment from "moment/moment";
import {exportAiProfileExcelProducersSuccess} from "../main/activeIngredientProfile/actions";


function* getExhibitionLogSaga(params) {
    try {
        const response = yield call(getExhibitionLogRequest, params.filters);
        yield put(setExhibitionLogPagination(response.meta))
        yield put(getExhibitionLogSuccess(response.data));
    } catch (error) {
        yield put(dataError(error))
    }
}

function* getExhibitionVisitorsLogSaga(params) {
    try {
        const response = yield call(getExhibitionVisitorsLogRequest, params.filters);
        yield put(setExhibitionVisitorsLogPagination(response.meta))
        yield put(getExhibitionVisitorsLogSuccess(response.data));
    } catch (error) {
        yield put(dataError(error))
    }
}

function* exportExhibitionVisitorsSaga() {
    try {
        const filename  = 'visitors-report-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        console.log(filename);
        const response = yield call(exportExhibitionLogRequest, filename);
        yield put(exportExhibitionLogSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}

function* exhibitionLogSaga() {
    yield takeEvery(GET_EXHIBITION_LOG, getExhibitionLogSaga)
    yield takeEvery(GET_VISITORS_EXHIBITION_LOG, getExhibitionVisitorsLogSaga)
    yield takeEvery(EXPORT_EXHIBITION_LOG, exportExhibitionVisitorsSaga)
}

export default exhibitionLogSaga
