import { call, takeEvery, put } from 'redux-saga/effects';
import { RUN_COMMAND, GET_COMMANDS } from './actionTypes';
import {
    getCommandsSuccess,
    getCommandsError,
    runCommandSuccess,
    runCommandError,
    setLoading,
    resetLoading, getCommands
} from "./actions";

import {
    getSchedules,
    runCommandRequest
} from '../../library/http/backendHelpers'


function* runCommandSaga({payload: {command}}) {
    try {
        yield put(setLoading(command));
        const response = yield call(runCommandRequest, command);
        yield put(runCommandSuccess(response.data));
    }catch (error) {
        yield put(runCommandError(error))
    } finally {
        yield put(getCommands())
        yield put(resetLoading(command));
    }
}

function* getSchedulesSaga() {
    try {
        const response = yield call(getSchedules);
        yield put(getCommandsSuccess(response.data));
    }catch (error) {
        yield put(getCommandsError(error))
    }
}


function* scheduleSaga() {
    yield takeEvery(RUN_COMMAND, runCommandSaga);
    yield takeEvery(GET_COMMANDS, getSchedulesSaga);
}

export default scheduleSaga
