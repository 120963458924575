export const GET_EXHIBITION_LOG = "GET_EXHIBITION_LOG";
export const GET_EXHIBITION_LOG_SUCCESS = "GET_EXHIBITION_LOG_SUCCESS";
export const GET_PAGINATION_EXHIBITION_LOG = "GET_PAGINATION_EXHIBITION_LOG";

export const GET_VISITORS_EXHIBITION_LOG = "GET_VISITORS_EXHIBITION_LOG";
export const GET_VISITORS_EXHIBITION_LOG_SUCCESS = "GET_VISITORS_EXHIBITION_LOG_SUCCESS";
export const GET_PAGINATION_VISITORS_EXHIBITION_LOG = "GET_PAGINATION_VISITORS_EXHIBITION_LOG";

export const EXPORT_EXHIBITION_LOG = "EXPORT_EXHIBITION_LOG";
export const EXPORT_EXHIBITION_LOG_SUCCESS = "EXPORT_EXHIBITION_LOG_SUCCESS";

export const GET_EXHIBITION_LOG_DATA_ERROR = "GET_EXHIBITION_LOG_DATA_ERROR";
export const CLEAR_EXHIBITION_LOG_DATA_STATE = "CLEAR_EXHIBITION_LOG_DATA_STATE";
