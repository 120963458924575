import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Switch, DatePicker, Spin, Typography, AutoComplete} from "antd";
import basicStyle from "@iso/assets/styles/constants";
import {useDispatch, useSelector} from "react-redux";
import {countries} from '../../../config/data/countries';
import {validateFromServer} from "../../../library/helpers/validators/serverValidation";
import {createCompanies, clearState, updateCompany} from "../../../redux/companies/actions";

const CompanyForm = () => {
    const {modalVisibility} = useSelector(state => state.rootModal)
    const {company, formLoading, error } = useSelector(state => state.Companies)
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields()
    }, [modalVisibility])

    if (error) {
        if (error.code === 422) {
            form.setFields(validateFromServer(error));
            dispatch(clearState());
        }
    }

    const onFinish = (values) => {
        if (Object.keys(company).length === 0) {
            dispatch(createCompanies(values))
        }else{
            dispatch(updateCompany(values, company.id))
        }
    }

    return (
        <Spin spinning={formLoading}>
            <Form
                id="company_form"
                name="company_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                onFinish={onFinish}
                initialValues={company}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter company name",
                                        },
                                    ]}
                                >
                                    <Input style={{width: "100%"}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Country"
                                name="country"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter company country",
                                    },
                                ]}
                            >
                                <AutoComplete
                                    options={countries}
                                    placeholder="Type to search for country"
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                >
                                    <Input style={{width: "100%"}}/>
                                </AutoComplete>
                            </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default CompanyForm
