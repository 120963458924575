export const POST_LOGIN = "/login";
export const POST_REGISTER = "/register";
export const VERIFY_EMAIL_URL = "/verify-email";
export const RESEND_VERIFICATION_EMAIL = "/email/verification-notification";
export const PASSWORD_CHANGE = "/change-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const PASSWORD_RESET = "/reset-password";
export const REP_COMPANIES = "/representing-companies";
export const GET_USER_STATE = "/user-state";
export const PROFILE = '/profile';

export const REPORT = '/report';

export const GET_MENU = "/menu";
export const GET_ROUTES = "/routes";

//USERS
export const USERS = '/users'
//SCHEDULES
export const SCHEDULES = '/schedules'
export const COMMANDS = '/command/run'
//COMPANIES
export const COMPANIES = '/companies'
//COMPANY
export const COMPANY = '/company'
//LOGS
export const LOGS = '/logs'

//SUBSCRIPTION
export const SUBSCRIPTION = '/subscriptions'

export const TRAIL = '/trail'
//
export const SEARCH_PRODUCT = '/search-products';

export const ACTIVE_INGREDIENT = '/active-ingredient';

export const SEARCH_COMPANIES = '/search-companies';

export const PRODUCERS_COMPANIES = '/producers-company';
