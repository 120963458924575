import {
    COMPANIES_SEARCH_LOGS,
    COMPANIES_SEARCH_LOGS_SUCCESS,
    GET_PAGINATION_COMPANIES_SEARCH_LOGS,
    EXPORT_COMPANIES_SEARCH_LOGS,
    EXPORT_COMPANIES_SEARCH_LOGS_SUCCESS,
    COMPANIES_VIEW_LOGS,
    COMPANIES_VIEW_LOGS_SUCCESS,
    GET_PAGINATION_COMPANIES_VIEW_LOGS,
    PRODUCTS_SEARCH_LOGS,
    PRODUCTS_SEARCH_LOGS_SUCCESS,
    GET_PAGINATION_PRODUCTS_SEARCH_LOGS,
    PRODUCTS_VIEW_LOGS,
    PRODUCTS_VIEW_LOGS_SUCCESS,
    GET_PAGINATION_PRODUCTS_VIEW_LOGS,
    GET_APP_LOGS_DATA_ERROR,
    CLEAR_APP_LOGS_STATE,
    EXPORT_COMPANIES_VIEW_LOGS,
    EXPORT_COMPANIES_VIEW_LOGS_SUCCESS,
    EXPORT_PRODUCTS_SEARCH_LOGS,
    EXPORT_PRODUCTS_SEARCH_LOGS_SUCCESS,
    EXPORT_PRODUCTS_VIEW_LOGS,
    EXPORT_PRODUCTS_VIEW_LOGS_SUCCESS
} from "./actionTypes";

export const getCompaniesSearchLogs = (params) => ({
    type: COMPANIES_SEARCH_LOGS,
    filters: {...params}
});

export const getCompaniesSearchLogsSuccess = activeIngredients => ({
    type: COMPANIES_SEARCH_LOGS_SUCCESS,
    payload: activeIngredients,
});

export const setCompaniesSearchPagination = pagination => ({
    type: GET_PAGINATION_COMPANIES_SEARCH_LOGS,
    payload: pagination,
});

export const exportCompaniesSearchLogs= () => ({
    type: EXPORT_COMPANIES_SEARCH_LOGS,
})

export const exportCompaniesSearchLogsSuccess = () => ({
    type: EXPORT_COMPANIES_SEARCH_LOGS_SUCCESS,
});


export const getCompaniesViewLogs = (params) => ({
    type: COMPANIES_VIEW_LOGS,
    filters: {...params}
});

export const getCompaniesViewLogsSuccess = activeIngredients => ({
    type: COMPANIES_VIEW_LOGS_SUCCESS,
    payload: activeIngredients,
});

export const setCompaniesViewPagination = pagination => ({
    type: GET_PAGINATION_COMPANIES_VIEW_LOGS,
    payload: pagination,
});

export const exportCompaniesViewLogs = () => ({
    type: EXPORT_COMPANIES_VIEW_LOGS,
})

export const exportCompaniesViewLogsSuccess = () => ({
    type: EXPORT_COMPANIES_VIEW_LOGS_SUCCESS,
});



export const getProductsSearchLogs = (params) => ({
    type: PRODUCTS_SEARCH_LOGS,
    filters: {...params}
});

export const getProductsSearchLogsSuccess = activeIngredients => ({
    type: PRODUCTS_SEARCH_LOGS_SUCCESS,
    payload: activeIngredients,
});

export const setProductsSearchPagination = pagination => ({
    type: GET_PAGINATION_PRODUCTS_SEARCH_LOGS,
    payload: pagination,
});

export const exportProductsSearchLogs = () => ({
    type: EXPORT_PRODUCTS_SEARCH_LOGS,
})

export const exportProductsSearchLogsSuccess = () => ({
    type: EXPORT_PRODUCTS_SEARCH_LOGS_SUCCESS,
});


export const getProductsViewLogs = (params) => ({
    type: PRODUCTS_VIEW_LOGS,
    filters: {...params}
});

export const getProductsViewLogsSuccess = activeIngredients => ({
    type: PRODUCTS_VIEW_LOGS_SUCCESS,
    payload: activeIngredients,
});

export const setProductsViewPagination = pagination => ({
    type: GET_PAGINATION_PRODUCTS_VIEW_LOGS,
    payload: pagination,
});

export const exportProductsViewLogs = () => ({
    type: EXPORT_PRODUCTS_VIEW_LOGS,
})

export const exportProductsViewLogsSuccess = () => ({
    type: EXPORT_PRODUCTS_VIEW_LOGS_SUCCESS,
});


export const dataError = error => ({
    type: GET_APP_LOGS_DATA_ERROR,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_APP_LOGS_STATE,
    payload: state
});
