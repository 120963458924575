import {
    GET_COMPANIES,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_DATA_ERROR,
    CLEAR_COMPANIES_STATE,
    SET_COMPANY,
    GET_PAGINATION_COMPANIES,
    CREATE_COMPANIES,
    CREATE_COMPANIES_SUCCESS,
    CREATE_COMPANIES_FAIL,
    UPDATE_COMPANIES,
    UPDATE_COMPANIES_SUCCESS,
    UPDATE_COMPANIES_FAIL,
    DELETE_COMPANIES,
    DELETE_COMPANIES_SUCCESS
} from "./actionTypes";


const initialState = {
    companies: [],
    company: {},
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    loading: false,
    formLoading: false,
    success: '',
    error: '',
};

const Companies = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANIES:
            return {
                ...state,
                loading: true
            };
        case GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                loading: false
            }
        case CREATE_COMPANIES:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_COMPANIES_SUCCESS:
            return {
                ...state,
                success: 'Companies was successfully created',
                companies: [action.payload, ...state.companies],
                formLoading: false
            };
        case CREATE_COMPANIES_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case UPDATE_COMPANIES:
            return {
                ...state,
                formLoading: true,
            };
        case UPDATE_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: state.companies.map((company) => company.id === action.payload.id ? action.payload : company),
                success: "Company was successfully edited",
                formLoading: false,
            };
        case UPDATE_COMPANIES_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case DELETE_COMPANIES:
            return {
                ...state,
                loading: true
            };
        case DELETE_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: state.companies.filter(company => company.id !== action.payload),
                success: "Company was successfully deleted",
                loading: false
            };
        case GET_COMPANIES_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case SET_COMPANY:
            return {
                ...state,
                company: action.payload
            };
        case GET_PAGINATION_COMPANIES:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_COMPANIES_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default Companies;
