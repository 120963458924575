import React, {lazy, Suspense} from 'react';
import {
    Route,
    Redirect,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import {PUBLIC_ROUTE} from './route.constants';
import Loader from '@iso/components/utility/loader';
import {Alert} from "antd";

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import('@iso/pages/Auth/Login')),
    },

    {
        path: PUBLIC_ROUTE.PAGE_500,
        component: lazy(() => import('@iso/containers/Pages/500/500')),
    },
    {
        path: PUBLIC_ROUTE.LOGIN,
        component: lazy(() => import('@iso/pages/Auth/Login')),
    },
    {
        path: PUBLIC_ROUTE.FORGET_PASSWORD,
        component: lazy(() =>
            import('@iso/pages/Auth/ForgotPassword/ForgotPassword')
        ),
    },
    {
        path: PUBLIC_ROUTE.PAGE_404,
        component: lazy(() => import('@iso/containers/Pages/404/404')),
    },
    {
        path: '/password-reset/:email/:token',
        component: lazy(() =>  import('@iso/pages/Auth/PasswordReset')),
        exact: true
    }
];

const verifyRoutes = [
    {
        path: '/email-verify/:id/:hash',
        component: lazy(() =>  import('@iso/pages/Auth/VerifyEmail')),
        exact: true
    },
    {
        path: '/confirm-email',
        component: lazy(() =>  import('@iso/pages/Auth/ConfirmEmail/ConfirmEmail')),
        exact: true
    }
];

const passwordChange = [
    {
        path: '/change-password',
        component: lazy(() => import('@iso/pages/Auth/ResetPassword/ResetPassword')),
    }
];

const withoutSubscription = [
    {
        path: '/without-permission',
        component: lazy(() => import('@iso/main/pages/WithoutPermission')),
    }
];



function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({location}) => {
                if (!localStorage.getItem('token') || !localStorage.getItem('user')) {
                    return (<Redirect
                        to={{
                            pathname: '/login',
                            state: {from: location},
                        }}
                    />)
                }
                if (localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem('user_verified') === "false") {
                    return ( <Redirect
                        to={{ pathname: "/confirm-email" }}
                    />)
                }
                if (localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem('user_verified') === "true" && localStorage.getItem('password_change') === "true") {
                    return ( <Redirect
                        to={{ pathname: "/change-password" }}
                    />)
                }
                if (localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem('user_verified') === "true" && localStorage.getItem('password_change') === "false" && localStorage.getItem('has_api') === "false") {
                    return ( <Redirect
                        to={{ pathname: "/without-permission" }}
                    />)
                }else {
                    return (
                        children
                    )
                }
            }
            }
        />
    );
}

function PublicRoutes({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => {
                if (localStorage.getItem('token') && localStorage.getItem('user') ) {
                    if (localStorage.getItem('user_verified') === "false") {
                        return ( <Redirect
                            to={{ pathname: "/confirm-email", state: { from: props.location } }}
                        />)
                    }
                    if (localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem('user_verified') === "true" && localStorage.getItem('password_change') === "true") {
                        return ( <Redirect
                            to={{ pathname: "/change-password", state: { from: props.location } }}
                        />)
                    }
                    return ( <Redirect
                        to={{ pathname: "/dashboard", state: { from: props.location } }}
                    />)
                }
                return (
                    children
                )
            }}
        />
    );
}

function VerifyRoutes({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => {
                if (localStorage.getItem('token') && localStorage.getItem('user')) {
                    if (localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem('user_verified') === "true" && localStorage.getItem('password_change') === "true") {
                        return ( <Redirect
                            to={{ pathname: "/change-password", state: { from: props.location } }}
                        />)
                    }
                    if (localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem('user_verified') === "true" && localStorage.getItem('password_change') === "false") {
                        return ( <Redirect
                            to={{ pathname: "/dashboard", state: { from: props.location } }}
                        />)
                    }
                    return (
                        children
                    )
                }else{
                    return (<Redirect
                        to={{
                            pathname: '/login'
                        }}
                    />)
                }
            }}
        />
    );
}

function PasswordChange({children, ...rest}) {
    return (
        <Route
            {...rest}
            render={props => {
                if (localStorage.getItem('token') && localStorage.getItem('user') ) {
                    if (localStorage.getItem('user_verified') === "false") {
                        return ( <Redirect
                            to={{ pathname: "/confirm-email", state: { from: props.location } }}
                        />)
                    }
                    if (localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem('user_verified') === "true"  && localStorage.getItem('password_change') === "false") {
                        return ( <Redirect
                            to={{ pathname: "/dashboard", state: { from: props.location } }}
                        />)
                    }else{
                        return (
                            children
                        )
                    }
                }
            }}
        />
    )
}

function WithoutSubscription({children, ...rest}) {
    console.log('teste')
    return (
        <Route
            {...rest}
            render={props => {
                if (!localStorage.getItem('token') || !localStorage.getItem('user')) {
                    return (<Redirect
                        to={{
                            pathname: '/login',
                            state: {from: props.location},
                        }}
                    />)
                }
                if (localStorage.getItem('token') && localStorage.getItem('user') && localStorage.getItem('user_verified') === "true"  && localStorage.getItem('password_change') === "false" && localStorage.getItem('has_api') === "true") {
                    return ( <Redirect
                        to={{ pathname: "/dashboard", state: { from: props.location } }}
                    />)
                }else{
                    return (
                        children
                    )
                }
            }}
        />
    )
}

export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader/>}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <PublicRoutes key={index} path={route.path} exact={route.exact}>
                                <route.component/>
                            </PublicRoutes>
                        ))}
                        {verifyRoutes.map((route, index) => (
                            <VerifyRoutes key={index} path={route.path} exact={route.exact}>
                                <route.component />
                            </VerifyRoutes>
                        ))}
                        {passwordChange.map((route, index) => (
                            <PasswordChange key={index} path={route.path} exact={route.exact}>
                                <route.component />
                            </PasswordChange>
                        ))}
                        {withoutSubscription.map((route, index) => (
                            <WithoutSubscription key={index} path={route.path} exact={route.exact}>
                                <route.component />
                            </WithoutSubscription>
                        ))}
                        <PrivateRoute path="/dashboard">
                            <Dashboard/>
                        </PrivateRoute>
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}
