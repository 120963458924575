export const COMPANIES_SEARCH_LOGS = "COMPANIES_SEARCH_LOGS";
export const COMPANIES_SEARCH_LOGS_SUCCESS = "COMPANIES_SEARCH_LOGS_SUCCESS";
export const GET_PAGINATION_COMPANIES_SEARCH_LOGS = "GET_PAGINATION_COMPANIES_SEARCH_LOGS"


export const EXPORT_COMPANIES_SEARCH_LOGS = "EXPORT_COMPANIES_SEARCH_LOGS";
export const EXPORT_COMPANIES_SEARCH_LOGS_SUCCESS = "EXPORT_COMPANIES_SEARCH_LOGS_SUCCESS";



export const COMPANIES_VIEW_LOGS = "COMPANIES_VIEW_LOGS";
export const COMPANIES_VIEW_LOGS_SUCCESS = "COMPANIES_VIEW_LOGS_SUCCESS";
export const GET_PAGINATION_COMPANIES_VIEW_LOGS = "GET_PAGINATION_COMPANIES_VIEW_LOGS"


export const EXPORT_COMPANIES_VIEW_LOGS = "EXPORT_COMPANIES_VIEW_LOGS";
export const EXPORT_COMPANIES_VIEW_LOGS_SUCCESS = "EXPORT_COMPANIES_VIEW_LOGS_SUCCESS";



export const PRODUCTS_SEARCH_LOGS = "PRODUCTS_SEARCH_LOGS";
export const PRODUCTS_SEARCH_LOGS_SUCCESS = "PRODUCTS_SEARCH_LOGS_SUCCESS";
export const GET_PAGINATION_PRODUCTS_SEARCH_LOGS = "GET_PAGINATION_PRODUCTS_SEARCH_LOGS"


export const EXPORT_PRODUCTS_SEARCH_LOGS = "EXPORT_PRODUCTS_SEARCH_LOGS";
export const EXPORT_PRODUCTS_SEARCH_LOGS_SUCCESS = "EXPORT_PRODUCTS_SEARCH_LOGS_SUCCESS";


export const PRODUCTS_VIEW_LOGS = "PRODUCTS_VIEW_LOGS";
export const PRODUCTS_VIEW_LOGS_SUCCESS = "PRODUCTS_VIEW_LOGS_SUCCESS";
export const GET_PAGINATION_PRODUCTS_VIEW_LOGS = "GET_PAGINATION_PRODUCTS_VIEW_LOGS"


export const EXPORT_PRODUCTS_VIEW_LOGS = "EXPORT_PRODUCTS_VIEW_LOGS";
export const EXPORT_PRODUCTS_VIEW_LOGS_SUCCESS = "EXPORT_PRODUCTS_VIEW_LOGS_SUCCESS";


export const GET_APP_LOGS_DATA_ERROR = "GET_APP_LOGS_DATA_ERROR";
export const CLEAR_APP_LOGS_STATE = "CLEAR_APP_LOGS_STATE";
