import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_AI_VERIFIED_PRODUCERS,
    GET_AI_RELATED_PRODUCERS, GET_AI_OTHER_PRODUCERS, EXPORT_AI_PROFILE_EXCEL_REPORT, EXPORT_AI_PROFILE_PDF_REPORT,GET_AI_PROFILE_DATA
} from "./actionTypes";
import moment from "moment";

import {
    getAiVerifiedProducersSuccess,
    getAiRelatedProducersSuccess,
    setAiVerifiedProducersPagination,
    setAiRelatedProducersPagination,
    dataError,
    getAiOtherProducersSuccess,
    setAiOtherProducersPagination,
    exportAiProfileExcelProducersSuccess, exportAiProfilePdfProducersSuccess, getAiProfileDataSuccess,
} from "./actions";

import {
    aiProducersProfileRequest,
    aiProducersRelatedRequest,
    aiProducersOtherRequest,
    exportAiProfileExcelProducersRequest,
    exportAiProfilePdfProducersRequest, companyAiDataRequest
} from '../../../library/http/backendHelpers'

function* getAiVerifiedProducersSaga({ payload: { product_id, params }}) {
    try {
        const response = yield call(aiProducersProfileRequest, product_id, params);
        yield put(getAiVerifiedProducersSuccess(response.data));
        yield put(setAiVerifiedProducersPagination(response.meta));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getAiRelatedProducersSaga({ payload: { product_id, params}}) {
    try {
        const response = yield call(aiProducersRelatedRequest, product_id, params);
        yield put(getAiRelatedProducersSuccess(response.data));
        yield put(setAiRelatedProducersPagination(response.meta));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getAiOtherProducersSaga({ payload: { product_id, params}}) {
    try {
        const response = yield call(aiProducersOtherRequest, product_id, params);
        yield put(getAiOtherProducersSuccess(response.data));
        yield put(setAiOtherProducersPagination(response.meta));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* exportAiProfileExcelProducersSaga({ payload: { product_id, params}}) {
    try {
        const filename  = 'api-producers-company-report-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportAiProfileExcelProducersRequest, product_id, params, filename);
        yield put(exportAiProfileExcelProducersSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}

function* exportAiProfilePdfProducersSaga({ payload: { product_id, params}}) {
    try {
        const filename  = 'api-producers-company-report-'+ moment().format('Y-m-d HH:mm:ss') + '.pdf';
        const response = yield call(exportAiProfilePdfProducersRequest, product_id, params, filename);
        yield put(exportAiProfilePdfProducersSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getAiProfileDataSaga({ payload: { product_id }}) {
    try {
        const response = yield call(companyAiDataRequest, product_id);
        yield put(getAiProfileDataSuccess(response.data));
    } catch (error) {
        yield put(dataError(error))
    }
}
function* activeIngredientsProfileSaga() {
    yield takeEvery(GET_AI_VERIFIED_PRODUCERS, getAiVerifiedProducersSaga)
    yield takeEvery(GET_AI_RELATED_PRODUCERS, getAiRelatedProducersSaga)
    yield takeEvery(GET_AI_OTHER_PRODUCERS, getAiOtherProducersSaga)
    yield takeEvery(EXPORT_AI_PROFILE_EXCEL_REPORT, exportAiProfileExcelProducersSaga)
    yield takeEvery(EXPORT_AI_PROFILE_PDF_REPORT, exportAiProfilePdfProducersSaga)
    yield takeEvery(GET_AI_PROFILE_DATA, getAiProfileDataSaga)
}

export default activeIngredientsProfileSaga
