import React, {useEffect} from "react";
import {Col, Image, Row, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import ActiveIngredients from "../../../redux/main/activeIngredients/reducer";
import {getActiveIngredientImage} from "../../../redux/main/activeIngredients/actions";

const ProductChemicalStructureModal = ({id}) => {
    const dispatch = useDispatch();
    const {active_ingredient_image, imageLoading} = useSelector(state => state.ActiveIngredients)

    useEffect(() => {
        dispatch(getActiveIngredientImage(id))
    }, [id])

    return (
        <Row gutter={[16, 16]}>
            <Col span={8} style={{textAlign: "center"}}>
               <Spin spinning={imageLoading}>
                <Image
                width={300}
                src={`data:image/jpeg;base64,${active_ingredient_image}`}
                /></Spin>
               </Col>
        </Row>
    )
}

export default ProductChemicalStructureModal;
