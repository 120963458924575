import { call, takeEvery, put } from 'redux-saga/effects';
import {
    COMPANIES_SEARCH_LOGS,
    COMPANIES_VIEW_LOGS,
    PRODUCTS_SEARCH_LOGS,
    PRODUCTS_VIEW_LOGS,

    EXPORT_COMPANIES_SEARCH_LOGS,
    EXPORT_COMPANIES_VIEW_LOGS,
    EXPORT_PRODUCTS_SEARCH_LOGS,
    EXPORT_PRODUCTS_VIEW_LOGS
} from "./actionTypes";

import {
    getCompaniesSearchLogsSuccess,
    setCompaniesSearchPagination,
    exportCompaniesSearchLogsSuccess,

    getCompaniesViewLogsSuccess,
    setCompaniesViewPagination,
    exportCompaniesViewLogsSuccess,

    getProductsSearchLogsSuccess,
    setProductsSearchPagination,
    exportProductsSearchLogsSuccess,

    getProductsViewLogsSuccess,
    setProductsViewPagination,
    exportProductsViewLogsSuccess,

    dataError
} from "./actions";

import {
    getCompaniesSearchLogsRequest,
    getCompaniesViewLogsRequest,
    getProductsSearchLogsRequest,
    getProductsViewLogsRequest,

    exportCompaniesSearchLogsRequest,
    exportCompaniesViewLogsRequest,
    exportProductsSearchLogsRequest,
    exportProductsViewLogsRequest,
} from '../../library/http/backendHelpers'
import moment from "moment";


function* getCompaniesSearchLogsSaga(params) {
    try {
        const response = yield call(getCompaniesSearchLogsRequest, params.filters);
        yield put(setCompaniesSearchPagination(response.meta))
        yield put(getCompaniesSearchLogsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getCompaniesViewLogsSaga(params) {
    try {
        const response = yield call(getCompaniesViewLogsRequest, params.filters);
        yield put(setCompaniesViewPagination(response.meta))
        yield put(getCompaniesViewLogsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getProductsSearchLogsSaga(params) {
    try {
        const response = yield call(getProductsSearchLogsRequest, params.filters);
        yield put(setProductsSearchPagination(response.meta))
        yield put(getProductsSearchLogsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* getProductsViewLogsSaga(params) {
    try {
        const response = yield call(getProductsViewLogsRequest, params.filters);
        yield put(setProductsViewPagination(response.meta))
        yield put(getProductsViewLogsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* exportCompaniesSearchLogsSaga() {
    try {
        const filename  = 'company-searched-report-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportCompaniesSearchLogsRequest, filename);
        yield put(exportCompaniesSearchLogsSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}


function* exportCompaniesViewLogsSaga() {
    try {
        const filename  = 'company-viewed-report-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportCompaniesViewLogsRequest, filename);
        yield put(exportCompaniesViewLogsSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}


function* exportProductsSearchLogsSaga() {
    try {
        const filename  = 'products-searched-report-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportProductsSearchLogsRequest, filename);
        yield put(exportProductsSearchLogsSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}


function* exportProductsViewLogsSaga() {
    try {
        const filename  = 'products-viewed-report-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportProductsViewLogsRequest, filename);
        yield put(exportProductsViewLogsSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}


function* appLogsSaga() {
    yield takeEvery(COMPANIES_SEARCH_LOGS, getCompaniesSearchLogsSaga)
    yield takeEvery(COMPANIES_VIEW_LOGS, getCompaniesViewLogsSaga)
    yield takeEvery(PRODUCTS_SEARCH_LOGS, getProductsSearchLogsSaga)
    yield takeEvery(PRODUCTS_VIEW_LOGS, getProductsViewLogsSaga)

    yield takeEvery(EXPORT_COMPANIES_SEARCH_LOGS, exportCompaniesSearchLogsSaga)
    yield takeEvery(EXPORT_COMPANIES_VIEW_LOGS, exportCompaniesViewLogsSaga)
    yield takeEvery(EXPORT_PRODUCTS_SEARCH_LOGS, exportProductsSearchLogsSaga)
    yield takeEvery(EXPORT_PRODUCTS_VIEW_LOGS, exportProductsViewLogsSaga)
}

export default appLogsSaga
