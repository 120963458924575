import {
    GET_EXHIBITION_LOG,
    GET_EXHIBITION_LOG_SUCCESS,
    GET_PAGINATION_EXHIBITION_LOG,
    GET_VISITORS_EXHIBITION_LOG,
    GET_VISITORS_EXHIBITION_LOG_SUCCESS,
    GET_PAGINATION_VISITORS_EXHIBITION_LOG,
    EXPORT_EXHIBITION_LOG,
    EXPORT_EXHIBITION_LOG_SUCCESS,
    GET_EXHIBITION_LOG_DATA_ERROR,
    CLEAR_EXHIBITION_LOG_DATA_STATE
} from "./actionTypes";


const initialState = {
    exhibitions_logs: [],
    exhibitions_logs_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    exhibitions_logs_loading: false,

    exhibitions_visitors_log: [],
    exhibitions_visitors_log_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    exhibitions_visitors_log_loading: false,
    exportLoading: false,
    success: '',
    error: '',
};

const ExhibitionLog = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXHIBITION_LOG:
            return {
                ...state,
                exhibitions_logs_loading: true
            };
        case GET_EXHIBITION_LOG_SUCCESS:
            return {
                ...state,
                exhibitions_logs: action.payload,
                exhibitions_logs_loading: false
            };
        case GET_PAGINATION_EXHIBITION_LOG:
            return {
                ...state,
                exhibitions_logs_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };

        case GET_VISITORS_EXHIBITION_LOG:
            return {
                ...state,
                exhibitions_visitors_log_loading: true
            };
        case GET_VISITORS_EXHIBITION_LOG_SUCCESS:
            return {
                ...state,
                exhibitions_visitors_log: action.payload,
                exhibitions_visitors_log_loading: false
            };
        case GET_PAGINATION_VISITORS_EXHIBITION_LOG:
            return {
                ...state,
                exhibitions_visitors_log_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case EXPORT_EXHIBITION_LOG:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_EXHIBITION_LOG_SUCCESS:
            return {
                ...state,
                exportLoading: false
            };
        case GET_EXHIBITION_LOG_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                exhibitions_logs_loading: false,
                exhibitions_visitors_log_loading: false
            };
        case CLEAR_EXHIBITION_LOG_DATA_STATE:
            return {
                ...state,
                exhibitions_logs: [],
                exhibitions_logs_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                exhibitions_logs_loading: false,
                exhibitions_visitors_log: [],
                exhibitions_visitors_log_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                exhibitions_visitors_log_loading: false,
                success: '',
                error: '',
            };
        default:
            return state;
    }
}

export default ExhibitionLog;
