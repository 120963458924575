import {
    COMPANIES_SEARCH_LOGS,
    COMPANIES_SEARCH_LOGS_SUCCESS,
    GET_PAGINATION_COMPANIES_SEARCH_LOGS,
    COMPANIES_VIEW_LOGS,
    COMPANIES_VIEW_LOGS_SUCCESS,
    GET_PAGINATION_COMPANIES_VIEW_LOGS,
    PRODUCTS_SEARCH_LOGS,
    PRODUCTS_SEARCH_LOGS_SUCCESS,
    GET_PAGINATION_PRODUCTS_SEARCH_LOGS,
    PRODUCTS_VIEW_LOGS,
    PRODUCTS_VIEW_LOGS_SUCCESS,
    GET_PAGINATION_PRODUCTS_VIEW_LOGS,
    EXPORT_COMPANIES_SEARCH_LOGS,
    EXPORT_COMPANIES_SEARCH_LOGS_SUCCESS,
    EXPORT_COMPANIES_VIEW_LOGS,
    EXPORT_COMPANIES_VIEW_LOGS_SUCCESS,
    EXPORT_PRODUCTS_SEARCH_LOGS,
    EXPORT_PRODUCTS_SEARCH_LOGS_SUCCESS,
    EXPORT_PRODUCTS_VIEW_LOGS,
    EXPORT_PRODUCTS_VIEW_LOGS_SUCCESS,
    GET_APP_LOGS_DATA_ERROR,
    CLEAR_APP_LOGS_STATE
} from "./actionTypes";


const initialState = {
    companies_search: [],
    companies_search_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    companies_search_loading: false,
    companies_view: [],
    companies_view_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    companies_view_loading: false,
    //products
    products_search: [],
    products_search_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    products_search_loading: false,
    products_view: [],
    products_view_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    products_view_loading: false,
    exportLoading: false,
    success: '',
    error: '',
};

const AppLogs = (state = initialState, action) => {
    switch (action.type) {
        case COMPANIES_SEARCH_LOGS:
            return {
                ...state,
                companies_search_loading: true
            };
        case COMPANIES_SEARCH_LOGS_SUCCESS:
            return {
                ...state,
                companies_search: action.payload,
                companies_search_loading: false
            };
        case GET_PAGINATION_COMPANIES_SEARCH_LOGS:
            return {
                ...state,
                companies_search_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case EXPORT_COMPANIES_SEARCH_LOGS:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_COMPANIES_SEARCH_LOGS_SUCCESS:
            return {
                ...state,
                exportLoading: false
            };
        case COMPANIES_VIEW_LOGS:
            return {
                ...state,
                companies_view_loading: true
            };
        case COMPANIES_VIEW_LOGS_SUCCESS:
            return {
                ...state,
                companies_view: action.payload,
                companies_view_loading: false
            };
        case GET_PAGINATION_COMPANIES_VIEW_LOGS:
            return {
                ...state,
                companies_view_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case EXPORT_COMPANIES_VIEW_LOGS:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_COMPANIES_VIEW_LOGS_SUCCESS:
            return {
                ...state,
                exportLoading: false
            };

        case PRODUCTS_SEARCH_LOGS:
            return {
                ...state,
                products_search_loading: true
            };
        case PRODUCTS_SEARCH_LOGS_SUCCESS:
            return {
                ...state,
                products_search: action.payload,
                products_search_loading: false
            };
        case GET_PAGINATION_PRODUCTS_SEARCH_LOGS:
            return {
                ...state,
                products_search_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case EXPORT_PRODUCTS_SEARCH_LOGS:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_PRODUCTS_SEARCH_LOGS_SUCCESS:
            return {
                ...state,
                exportLoading: false
            };
        case PRODUCTS_VIEW_LOGS:
            return {
                ...state,
                products_view_loading: true
            };
        case PRODUCTS_VIEW_LOGS_SUCCESS:
            return {
                ...state,
                products_view: action.payload,
                products_view_loading: false
            };
        case GET_PAGINATION_PRODUCTS_VIEW_LOGS:
            return {
                ...state,
                products_view_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case EXPORT_PRODUCTS_VIEW_LOGS:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_PRODUCTS_VIEW_LOGS_SUCCESS:
            return {
                ...state,
                exportLoading: false
            };
        case GET_APP_LOGS_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                companies_search_loading: false,
                companies_view_loading: false,
                products_search_loading: false,
                products_view_loading: false,
            };
        case CLEAR_APP_LOGS_STATE:
            return {
                ...state,
                companies_search: [],
                companies_search_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                companies_search_loading: false,
                companies_view: [],
                companies_view_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                companies_view_loading: false,
                products_search: [],
                products_search_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                products_search_loading: false,
                products_view: [],
                products_view_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                products_view_loading: false,
                exportLoading: false,
                success: '',
                error: '',
            };
        default:
            return state;
    }
}

export default AppLogs;
