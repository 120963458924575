import React from "react";
import ContentHolder from '@iso/components/utility/contentHolder';
import {Button, Col, Divider, Collapse, Row, Typography, Spin, Timeline, Badge, Avatar, Tabs} from "antd";
import basicStyle from '@iso/assets/styles/constants';

const ProducersCompanyModal = ({company}) => {
    const { Title, Text, Paragraph} = Typography;
    const { rowStyle, colStyle, gutter } = basicStyle;

    const openWebsite = (url) => {
        window.open(url, '_blank');
    }
    return (
        <>
            <Row gutter={16}>
                <Col md={24} sm={24} xs={24} style={colStyle}>
                    <div className="company-content-container">
                        <Row gutter={24}>
                            <Col lg={8} md={12} sm={12} xs={12} style={colStyle}>
                                <Title level={3}>{company.name}</Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                {(company.address) && <Col md={16} sm={24} xs={24} style={colStyle}>
                                    <Text type="secondary">{company.address}</Text>
                                </Col>}
                                {(company.secondary_address) && <Col md={16} sm={24} xs={24} style={colStyle}>
                                    <Text type="secondary">{company.secondary_address}</Text>
                                </Col> }
                                {(company.city || company.province || company.postal_code) &&  <Col md={16} sm={24} xs={24} style={colStyle}>
                                    {<Text
                                        type="secondary">{[company.city, company.province, company.postal_code].filter(function (el) {
                                        return el;
                                    }).join(', ')}</Text>}
                                </Col> }
                                {(company.country) && <Col md={16} sm={24} xs={24} style={colStyle}>
                                    {<Text type="secondary">{company.country}</Text>}
                                </Col> }
                                <Divider style={{marginTop: 0}}/>
                                <Col md={16} sm={24} xs={24} style={colStyle}>
                                    <Text strong className="mainText">Tel: </Text>
                                    <Text type="secondary">{company.tel}</Text>
                                </Col>
                                <Col md={16} sm={24} xs={24} style={colStyle}>
                                    <Text strong className="mainText">Fax: </Text>
                                    <Text type="secondary">{company.fax}</Text>
                                </Col>
                                <Col md={16} sm={24} xs={24} style={colStyle}>
                                    <Text strong className="mainText">Email: </Text>
                                    <Text type="secondary">{company.email}</Text>
                                </Col>
                                <Col md={16} sm={24} xs={24} style={colStyle}>
                                    <Text strong className="mainText">Website: </Text>
                                    <Text type="secondary">{company.website}</Text>
                                </Col>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                                <Col md={24} sm={24} xs={24} style={colStyle}>
                                    <Text strong className="mainText">Subsidiary of: </Text>
                                    <Text type="secondary">{company.subsidiary_of}</Text>
                                </Col>
                                <Col md={24} sm={24} xs={24} style={colStyle}>
                                    <Text strong className="mainText">Formerly: </Text>
                                    <Text type="secondary">{company.formerly}</Text>
                                </Col>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            { company.contacts?.length >0 ?
                <><Divider orientation={'left'}>Contacts</Divider>
                    <Row gutter={16}>
                        <Col md={24} sm={24} xs={24} style={colStyle}>
                            { company.contacts.map((contact) => (
                                <Row gutter={24}>
                                    <Col md={8} sm={8} xs={8} style={colStyle}>
                                        <Text strong className="mainText">Contact name: </Text>
                                        {contact.name ? <Text type="secondary">{contact.name}</Text> : <Text disabled>no name</Text>}
                                    </Col>
                                    <Col md={8} sm={8} xs={8} style={colStyle}>
                                        <Text strong className="mainText">Title: </Text>
                                        {contact.title ? <Text type="secondary">{contact.title}</Text> : <Text disabled>no title</Text>}
                                    </Col>
                                    <Col md={8} sm={8} xs={8} style={colStyle}>
                                        <Text strong className="mainText">Email: </Text>
                                        {contact.email ? <Text type="secondary">{contact.email}</Text> : <Text disabled>no email</Text>}
                                    </Col>
                                </Row>
                            ))  }
                        </Col>
                    </Row> </>:  ''}
            { company.comments?.length >0 ?
                <>
                    <Divider orientation={'left'}>Comments</Divider>
                    <Row gutter={24}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={colStyle}>
                            <Paragraph>
                                <blockquote>In the process of internal desktop applications development, many different design specs and
                                    implementations would be involved, which might cause designers and developers difficulties and
                                    duplication and reduce the efficiency of development. </blockquote>
                            </Paragraph>
                        </Col>
                    </Row>

                </> : ''}
            </>
    )
}

export default ProducersCompanyModal;
