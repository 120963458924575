import React from "react";
import {Typography} from "antd";

const ProducersCommentsModal = ({comment}) => {
    const {Paragraph} = Typography;
    return (
        <Paragraph style={{fontSize: "16px"}}>
            {comment}
        </Paragraph>
    )
}

export default ProducersCommentsModal;
