import React, {useState} from "react";
import {Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from '@iso/components/uielements/button';
import {validateFromServer} from "../../../../../library/helpers/validators/serverValidation";
import {showNotification} from "../../../../../redux/notification/actions";
import {closeModalMain} from "../../../../../redux/rootModal/actions";
import {setCompanyUserPassword, clearState} from "../../../../../redux/company/actions";

const CompanySetNewUserPassword = ({user_id}) => {
    const [form] = Form.useForm();
    const {formLoading, success, error} = useSelector(state => state.Company)
    const [showMessage, setShowMessage] = useState(false)
    const dispatch = useDispatch();

    const validatePassword = (rule, value, callback) => {
        let password = form.getFieldsValue().password;
        if (value && value !== password) {
            callback("Passwords don't match");
        } else {
            callback();
        }
    };
    const resetPasswordFunction = (data) => {
       dispatch(setCompanyUserPassword(data, user_id))
    }

     if (error !== '') {
         if (error.code === 422) {
             form.setFields(validateFromServer(error));
         }
         dispatch(clearState());
     }
     const notification = (type, message) => {
         dispatch(showNotification({ type: type, title: message }));
         dispatch(clearState());
     };
     if (success !== "") {
         form.resetFields()
         notification("success", 'Password Was Reset Successfully');
         setShowMessage(true)
         dispatch(closeModalMain())
     }

    return (
        <Form className="isoResetPassForm" form={form} name="reset_password_form" id="reset_password_form" onFinish={resetPasswordFunction}>
            <Form.Item className="isoInputWrapper" name="password" rules={[{
                required: true,
                message: 'Please enter your Password!'
            },
            ]}>
                <Input.Password size="large"
                                type="password"
                                placeholder="New Password"
                                name="password"/>
            </Form.Item>
            <Form.Item className="isoInputWrapper" name="password_confirmation" rules={[{
                required: true,
                message: 'Please enter your Password Confirmation!'
            },
                {
                    validator: validatePassword
                }
            ]}>
                <Input.Password
                    size="large"
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmpassword"
                />
            </Form.Item>

            <div className="isoInputWrapper" style={{marginTop: "10px"}}>
                <Button type="primary" key="submit" form="reset_password_form" htmlType="submit" style={{width: "100%"}} size="large" loading={formLoading}>
                    Reset
                </Button>
                <span style={{fontSize: "13px"}}>*If you don’t see the email,  check your spam or junk folders!</span><br/><br/>
                <div style={{fontSize: '13px'}}><span>Password must:</span><br/>
                    - Be a minimum of 10 characters <br/>
                    - Include at least one lowercase letter (a-z) <br/>
                    - Include at least one uppercase letter (A-Z) <br/>
                    - Include at least one number (0-9) <br/>
                    - Include at least one special character
                </div>
            </div>
        </Form>
    );
}

export default CompanySetNewUserPassword;
