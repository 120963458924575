import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Popconfirm, Spin, Typography, Space} from "antd";
import basicStyle from "@iso/assets/styles/constants";
import {useDispatch, useSelector} from "react-redux";
import {validateFromServer} from "../../../library/helpers/validators/serverValidation";
import {resetUserPassword, clearState, createUser, updateUser, getCompanyUsers} from "../../../redux/users/actions";
import ChangePswForm from "./ChangePswForm";
import {InfoCircleOutlined} from "@ant-design/icons";

const UserForm = () => {
    const { colStyle } = basicStyle;
    const { TextArea } = Input;
    const {Text} = Typography
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [buttonType, setButtonType] = useState(0);
    const {error, companies, formData, user, formLoading, formSuccess} = useSelector(state => state.Users)
    const {modalType, modalProps} = useSelector(state => state.rootModal)

    const { Option } = Select;

    useEffect(() => {
        form.resetFields()
        dispatch(getCompanyUsers())
    }, [user])

    const changePassword = () => {
        dispatch(resetUserPassword(user.id))
    }

    if (error) {
        if (error.code === 422){
            form.setFields(validateFromServer(error));
        }
        dispatch(clearState());
    }

    const onFinish = (data) => {
        const formData =  {
            address: (data.address) ? data.address: '',
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            password: (data.password) ? data.password: null,
            phone: (data.phone) ? data.phone: '',
            roles: (data.roles) ? data.roles: [],
            company_id: (data.company_id) ? data.company_id: '',
            with_verification: buttonType
        }
        if (Object.keys(user).length > 0){
            dispatch(updateUser(formData, user.id))
        }else{
           dispatch(createUser(formData))
        }
    }

    return (
        <Spin spinning={formLoading}>
        <Form
            id="users_form"
            name="users_form"
            layout="vertical"
            form={form}
            style={{ width: "100%" }}
            onFinish={onFinish}
            initialValues={user}
        >
            <Row gutter={24}>
                <Col md={24} sm={12} xs={24} style={{ marginBottom: 0}}>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="First Name"
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input first name!",
                                    },
                                ]}
                            >
                                <Input placeholder="First Name" name="first_name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Last Name"
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input last name!",
                                    },
                                ]}
                            >
                                <Input placeholder="Last Name" name="last_name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input email!",
                                    },
                                ]}
                            >
                                <Input placeholder="Email" name="email" disabled={(Object.keys(user).length === 0) ? false: true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Company"
                                name="company_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a company!",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Company"
                                >
                                    {companies?.map(company => {
                                        return (<Option key={company.id} value={company.id}>{company.name} ({company.country})</Option>)
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="User Role"
                                name="roles"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a user role!",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="User Role"
                                >
                                    <Option key={1} value={1}>Admin</Option>
                                    <Option key={2} value={2}>User</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Phone Number"
                                name="phone"
                            >
                                <Input placeholder="Phone Number" name="phone" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Address"
                                name="address"
                            >
                                <Input placeholder="Address" name="address" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={4} sm={4} xs={4}>
                </Col>
                <Col md={20} sm={20} xs={20} style={{textAlign: "right"}}>
                    {(modalType === 'USER_FORM' && modalProps.title === 'Create User') ? <Space direction='horizontal'>
                        <Button form="users_form" key="submit" htmlType="submit" loading={formLoading} onClick={() => setButtonType(1)}>Add & Send Verification Email</Button>
                        <Button form="users_form" key="submit" htmlType="submit" type="primary" loading={formLoading} onClick={() => setButtonType(0)}>Add</Button>
                    </Space> : <Button form="users_form" key="submit" htmlType="submit" type="primary" loading={formLoading} onClick={() => setButtonType(0)}>Edit</Button>}
                </Col>
            </Row>
        </Form>
        </Spin>
    )
}

export default UserForm;
