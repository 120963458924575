import {call, put, takeEvery} from "redux-saga/effects";
import {
    getUsers,
    createUsers,
    editUser,
    userPasswordChange,
    deleteUserRequest, getCompanies,
    sentUserVerificationRequest, setNewUserPasswordRequest, verifyUserRequest
} from '../../library/http/backendHelpers';

import {
    createUserFail,
    createUserSuccess,
    dataError,
    getUsersSuccess,
    updateUserFail,
    updateUserSuccess,
    changeUserPasswordSuccess,
    setPagination,
    deleteUserSuccess,
    resetUserPasswordSuccess,
    getCompanyUsersSuccess,
    sentUserVerificationSuccess,
    sentUserVerificationFail, setNewUserPasswordSuccess, setNewUserPasswordFail, verifyUser, verifyUserSuccess
} from "./actions";

import {
    GET_USERS,
    CREATE_USER,
    UPDATE_USER,
    CHANGE_USER_PASSWORD,
    DELETE_USER,
    RESET_USER_PASSWORD,
    GET_USERS_COMPANIES,
    SENT_USER_VERIFICATION,
    SET_NEW_USER_PASSWORD, VERIFY_USER
} from "./actionTypes";

function* fetchUsers(params) {
    try {
        const response = yield call(getUsers, params.filters);
        yield put(setPagination(response.meta))
        yield put(getUsersSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* fetchUsersCompanies1() {
    try {
        const response = yield call(getCompanies, {per_page: 10000000, orderby: "id", direction: "desc"});
        yield put(getCompanyUsersSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* createUser({ payload: user }) {
    try {
        const response = yield call(createUsers, user);
        yield put(createUserSuccess(response.data));
    }catch (error) {
        yield put(createUserFail(error))
    }
}

function* updateUser({ payload: {user, user_id}}) {
    try {
        const response = yield call(editUser, user, user_id);
        yield put(updateUserSuccess(response.data));
    }catch (error) {
        yield put(updateUserFail(error))
    }
}

function* sentUserVerificationSaga({ payload: {user_id}}) {
    try {
        const response = yield call(sentUserVerificationRequest, user_id);
        yield put(sentUserVerificationSuccess(response.data));
    }catch (error) {
        yield put(sentUserVerificationFail(error))
    }
}

function* deleteUser({ payload: {user_id}}) {
    try {
        const response = yield call(deleteUserRequest, user_id);
        yield put(deleteUserSuccess(user_id));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* resetUserPassword({ payload: {user_id}}) {
    try {
        const response = yield call(userPasswordChange, user_id);
        yield put(resetUserPasswordSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}


function* setNewUserPasswordSaga({ payload: {password, user_id}}) {
    try {
        const response = yield call(setNewUserPasswordRequest, password, user_id);
        yield put(setNewUserPasswordSuccess());
    }catch (error) {
        yield put(setNewUserPasswordFail(error))
    }
}

function* verifyUserSaga({ payload: {user_id}}) {
    try {
        const response = yield call(verifyUserRequest, user_id);
        yield put(verifyUserSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}


function* usersSaga() {
    yield takeEvery(GET_USERS, fetchUsers);
    yield takeEvery(CREATE_USER, createUser);
    yield takeEvery(UPDATE_USER, updateUser);
    yield takeEvery(SENT_USER_VERIFICATION, sentUserVerificationSaga);
    yield takeEvery(DELETE_USER, deleteUser);
    yield takeEvery(RESET_USER_PASSWORD, resetUserPassword);
    yield takeEvery(GET_USERS_COMPANIES, fetchUsersCompanies1);
    yield takeEvery(SET_NEW_USER_PASSWORD, setNewUserPasswordSaga);
    yield takeEvery(VERIFY_USER, verifyUserSaga);
}

export default usersSaga;
