export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";

export const CREATE_COMPANIES = "CREATE_COMPANIES";
export const CREATE_COMPANIES_SUCCESS = "CREATE_COMPANIES_SUCCESS";
export const CREATE_COMPANIES_FAIL = "CREATE_COMPANIES_FAIL";

export const UPDATE_COMPANIES = "UPDATE_COMPANIES";
export const UPDATE_COMPANIES_SUCCESS = "UPDATE_COMPANIES_SUCCESS";
export const UPDATE_COMPANIES_FAIL = "UPDATE_COMPANIES_FAIL";

export const DELETE_COMPANIES = "DELETE_COMPANIES"
export const DELETE_COMPANIES_SUCCESS = "DELETE_COMPANIES_SUCCESS"

export const SET_COMPANY = "SET_COMPANY";
export const GET_PAGINATION_COMPANIES = "GET_PAGINATION_COMPANIES"

export const GET_COMPANIES_DATA_ERROR = "GET_COMPANIES_DATA_ERROR";
export const CLEAR_COMPANIES_STATE = "CLEAR_COMPANIES_STATE";
