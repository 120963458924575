import {del, get, post, postUpload, downloadFile, put} from "./apiHelpers";
import * as url from './urlHelpers';
import {
    ACTIVE_INGREDIENT,
    COMPANY,
    PRODUCERS_COMPANIES,
    SCHEDULES,
    SEARCH_COMPANIES,
    SUBSCRIPTION,
    TRAIL
} from "./urlHelpers";

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);
// Register Method
const postRegister = data => post(url.POST_REGISTER, data);
//Verify Email
const verifyEmailRequest = (user_id, hash) => get(`${url.VERIFY_EMAIL_URL}/${user_id}/${hash}`);
//resend verification email
const resendVerificationEmail = () => post(url.RESEND_VERIFICATION_EMAIL);
//resend verification email
const resetPasswordRequest = (data) => post(url.PASSWORD_CHANGE, data);
//forgot email
const forgotPasswordRequest = (data) => post(url.FORGOT_PASSWORD, data);
//password reset
const passwordResetRequest = (data) => post(url.PASSWORD_RESET, data);
//password reset
const createRepCompaniesRequest = (data) => post(url.REP_COMPANIES, data);
// User State Method
const getUserState = () => get(url.GET_USER_STATE);
const getUserProfile = () => get(url.PROFILE)
const editUserProfile = (data) => put(url.PROFILE, data)
// Menu Methods
const getMenu = data => get(url.GET_MENU);
// Route Methods
const getRoutes = data => get(url.GET_ROUTES);

//Users Methods
const getUsers = (data) => get(url.USERS, {params: data});
const createUsers = (user) => post(url.USERS, user);
const editUser = (user, user_id) => put(url.USERS+'/'+user_id+'/edit', user)
const sentUserVerificationRequest = (user_id) => post(url.USERS+'/'+user_id+'/sent-verification')
const deleteUserRequest = (user_id) => del(url.USERS+'/'+user_id+'/delete')
const userPasswordChange = (user_id) => get(url.USERS+'/'+user_id+'/reset/password')
const setNewUserPasswordRequest = (password, user_id) => post(url.USERS+'/'+user_id+'/set-password', password)
const verifyUserRequest = (user_id) => get(url.USERS+'/'+user_id+'/verify')

//Schedules Methods
const getSchedules = () => get(url.SCHEDULES);
const runCommandRequest = (data) => post(url.COMMANDS, data)

//Companies Methods
const getCompanies = (data) => get(url.COMPANIES, {params: data});
const createCompaniesRequest = (company) => post(url.COMPANIES, company);
const updateCompaniesRequest = (company, company_id) => put(`${url.COMPANIES}/${company_id}/edit`, company);
const deleteCompaniesRequest = (company_id) => del(`${url.COMPANIES}/${company_id}`);

//Company Methods
const getCompanyRequest = (company_id) => get(`${url.COMPANY}/${company_id}`)
const getCompanyUsersRequest = (company_id, params) => get(`${url.COMPANY}/${company_id}/users`, {params: params})

//Subscriptions Methods
const getCompanySubscriptionsRequest = (company_id, params) => get(`${url.COMPANY}/${company_id}/subscriptions`, {params: params})
const createCompaniesSubscriptionRequest = (subscription) => post(url.SUBSCRIPTION, subscription);
const editCompaniesSubscriptionRequest = (subscription_id, subscription) => put(`${url.SUBSCRIPTION}/${subscription_id}/edit`, subscription);
const renewCompaniesSubscriptionRequest = (company_id, subscription_id) => put(`${url.SUBSCRIPTION}/${company_id}/renew/${subscription_id}`);
const cancelCompaniesSubscriptionRequest = (company_id, subscription_id) => put(`${url.SUBSCRIPTION}/${company_id}/cancel/${subscription_id}`);
const getSubscriptionsProducts = () => get('/products');

const deleteCompanySubscriptionRequest = (subscription_id) => del(`${url.SUBSCRIPTION}/${subscription_id}`)

const createCompaniesTrailRequest = (trail) => post(url.TRAIL, trail);
const updateCompaniesTrailRequest = (trail_id, trail) => put(`${url.TRAIL}/${trail_id}`, trail);
//Package Methods
const getSubscriptionsPackage = () => get('/packages');
const createSubscriptionsPackage = (packages) => post('/packages', packages);
const updateSubscriptionsPackage = (packages, packages_id) => put(`/packages/${packages_id}/edit`, packages);
const deleteSubscriptionsPackage = (packages_id) => del(`/packages/${packages_id}`);

//Logs
const getCompaniesSearchLogsRequest = (params) => get(`${url.LOGS}/companies-search`, {params: params})
const exportCompaniesSearchLogsRequest = (filename) => downloadFile(`${url.LOGS}/companies-search/excel`, {}, filename)
const getCompaniesViewLogsRequest = (params) => get(`${url.LOGS}/companies-view`, {params: params})
const exportCompaniesViewLogsRequest = (filename) => downloadFile(`${url.LOGS}/companies-view/excel`, {}, filename)
const getProductsSearchLogsRequest = (params) => get(`${url.LOGS}/products-search`, {params: params})
const exportProductsSearchLogsRequest = (filename) => downloadFile(`${url.LOGS}/products-search/excel`, {}, filename)
const getProductsViewLogsRequest = (params) => get(`${url.LOGS}/products-view`, {params: params})
const exportProductsViewLogsRequest = (filename) => downloadFile(`${url.LOGS}/products-view/excel`, {}, filename)

//USERS

//ActiveIngredient
const searchActiveIngredientRequest = (params) => get(`${url.SEARCH_PRODUCT}`, {params: params});
const aiProducersProfileRequest = (product_id, params) => get(`${url.ACTIVE_INGREDIENT}/${product_id}/profile`, {params: params});
const aiProducersRelatedRequest = (product_id, params) => get(`${url.ACTIVE_INGREDIENT}/${product_id}/related`, {params: params});
const aiProducersOtherRequest = (product_id, params) => get(`${url.ACTIVE_INGREDIENT}/${product_id}/other-possible`, {params: params});
const downloadProducersOtherRequest = (product_id, params, filename) => downloadFile(`${url.ACTIVE_INGREDIENT}/${product_id}/profile/export`, {params: params}, filename)
const exportAiProfileExcelProducersRequest = (product_id, params, filename) => downloadFile(`${url.ACTIVE_INGREDIENT}/${product_id}/profile/excel`, {params: params}, filename)
const exportAiProfilePdfProducersRequest = (product_id, params, filename) => downloadFile(`${url.ACTIVE_INGREDIENT}/${product_id}/profile/pdf`, {params: params}, filename)
const getActiveIngredientImageRequest = (product_id) => get(`${url.ACTIVE_INGREDIENT}/${product_id}/structure-image`)
//Companies
const searchProducersCompaniesRequest = (params) => get(`${url.SEARCH_COMPANIES}`, {params: params});
const producersCompaniesProfileRequest = (company_id, params) => get(`${url.PRODUCERS_COMPANIES}/${company_id}/profile`, {params: params});
const producersCompaniesOtherRequest = (company_id, params) => get(`${url.PRODUCERS_COMPANIES}/${company_id}/other-possible`, {params: params});
const producersCompaniesProductsRequest = (company_id, params) => get(`${url.PRODUCERS_COMPANIES}/${company_id}/products`, {params: params});

const exportCompanyProfileExcelProducersRequest = (company_id,params, filename) => downloadFile(`${url.PRODUCERS_COMPANIES}/${company_id}/profile/excel`, {params: params}, filename);
const exportCompanyProfilePdfProducersRequest = (company_id, params, filename) => downloadFile(`${url.PRODUCERS_COMPANIES}/${company_id}/profile/pdf`, {params: params}, filename);

const producersCompaniesRcdsRequest = (company_fm_id, type, params) => get(`${url.PRODUCERS_COMPANIES}/${company_fm_id}/rcd/${type}`, {params: params});

const companyProfileDataRequest = (company_id, params) => get(`${url.PRODUCERS_COMPANIES}/${company_id}`, {params: params});
const companyAiDataRequest = (product_id) => get(`${url.ACTIVE_INGREDIENT}/${product_id}`);

//Exhibitions logs
const getExhibitionLogRequest = (params) => get(`${url.LOGS}/exhibition-logs`, {params: params})

const exportExhibitionLogRequest = (filename) => downloadFile(`${url.LOGS}/visitors-logs-excel`, {}, filename)

const getExhibitionVisitorsLogRequest = (params) => get(`${url.LOGS}/visitors-logs`, {params: params})

export {
    postLogin,
    postRegister,
    verifyEmailRequest,
    resendVerificationEmail,
    resetPasswordRequest,
    getUserState,
    getMenu,
    getRoutes,
    getUsers,
    createUsers,
    editUser,
    sentUserVerificationRequest,
    deleteUserRequest,
    userPasswordChange,
    passwordResetRequest,
    forgotPasswordRequest,
    createRepCompaniesRequest,
    getUserProfile,
    editUserProfile,
    setNewUserPasswordRequest,
    verifyUserRequest,
    //schedules
    runCommandRequest,
    getSchedules,
    //companies
    getCompanies,
    createCompaniesRequest,
    updateCompaniesRequest,
    deleteCompaniesRequest,
    createCompaniesTrailRequest,
    updateCompaniesTrailRequest,
    deleteCompanySubscriptionRequest,
    //company
    getCompanyRequest,
    getCompanyUsersRequest,
    //subscriptions
    getCompanySubscriptionsRequest,
    createCompaniesSubscriptionRequest,
    editCompaniesSubscriptionRequest,
    getSubscriptionsProducts,
    renewCompaniesSubscriptionRequest,
    cancelCompaniesSubscriptionRequest,
    //packages
    getSubscriptionsPackage,
    createSubscriptionsPackage,
    updateSubscriptionsPackage,
    deleteSubscriptionsPackage,
    //logs
    getCompaniesSearchLogsRequest,
    getCompaniesViewLogsRequest,
    getProductsSearchLogsRequest,
    getProductsViewLogsRequest,
    exportCompaniesSearchLogsRequest,
    exportCompaniesViewLogsRequest,
    exportProductsSearchLogsRequest,
    exportProductsViewLogsRequest,

    //Exhibitions logs
    getExhibitionLogRequest,
    getExhibitionVisitorsLogRequest,

    //productSearch
    searchActiveIngredientRequest,
    aiProducersProfileRequest,
    aiProducersOtherRequest,
    aiProducersRelatedRequest,
    exportAiProfileExcelProducersRequest,
    exportAiProfilePdfProducersRequest,
    getActiveIngredientImageRequest,
    //company search
    searchProducersCompaniesRequest,
    producersCompaniesProfileRequest,
    companyProfileDataRequest,
    producersCompaniesOtherRequest,
    producersCompaniesProductsRequest,
    exportCompanyProfileExcelProducersRequest,
    exportCompanyProfilePdfProducersRequest,
    companyAiDataRequest,
    producersCompaniesRcdsRequest,
    exportExhibitionLogRequest,
    downloadProducersOtherRequest
}
