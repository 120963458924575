import { call, put, takeEvery } from "redux-saga/effects"
import {
    FORGOT_PASSWORD,
    LOGIN_REQUEST,
    LOGOUT, PASSWORD_RESET,
    REGISTER_REQUEST,
    RESEND_EMAIL,
    RESET_PASSWORD,
    SET_AUTH_STATE,
    VERIFY_EMAIL
} from "./actionTypes";
import {
    loginSuccess,
    loginError,
    setAuthStateSuccess,
    registerError,
    registerSuccess,
    registerValidationError,
    confirmEmailSuccess,
    confirmEmailError,
    resendVerificationEmailError,
    resendVerificationEmailSuccess,
    forgotPasswordSuccess,
    forgotPasswordError, passwordResetSuccess, passwordResetError,
} from "./actions";
import { getToken, clearToken } from '../../library/helpers/utility';
import {
    postLogin,
    getUserState,
    postRegister,
    verifyEmailRequest,
    resendVerificationEmail,
    resetPasswordRequest,
    forgotPasswordRequest,
    passwordResetRequest
} from '../../library/http/backendHelpers';



function* loginRequest({payload: {user, history}}) {
    try {
        const response = yield call(postLogin, user);
        yield put(loginSuccess(response))
        setLoginLocalStorage(response)
        redirect(response, history)
    } catch (error) {
        yield put(loginError(error));
    }
}


function* logout({payload: {history}}) {
    resetLoginLocalStorage();
    window.location.reload();
    history.push('/')
}

function* checkAuthorization() {
    try {
        if (localStorage.getItem('token')) {
            const response = yield call(getUserState);
            setUserStateLocalStorage(response);
        }
        const token = getToken().get('token');
        const user = getToken().get('user');
       if (token && user) {
           yield put(setAuthStateSuccess({
               token: token,
               user: JSON.parse(user),
           }))
       } else {
           yield put(setAuthStateSuccess({
               token: null,
               user: null
           }));
       }
    } catch (e) {
        resetLoginLocalStorage();
    }
}

export function* regitser({ payload: { user, history }}) {
    try {
        const response = yield call(postRegister, user);
        try {
            const response = yield call(postLogin, {
                email: user.email,
                password: user.password,
            });
            yield put(loginSuccess(response))
            setLoginLocalStorage(response)
        } catch (error) {
            resetLoginLocalStorage();
            yield put(loginError("Mismatch password"))
        }
        yield put(registerSuccess(response.data));
        redirect(response, history)
    }catch (error) {
        if(error.code === 422) {
            yield put(registerValidationError(error));
        }else{
            yield put(registerError(error));
        }
    }
}

export function* verifyEmail({ payload: { user_id, hash, history }}) {
    try {
        const response = yield call(verifyEmailRequest, user_id, hash);
        yield put(confirmEmailSuccess(response))
        localStorage.setItem("user_verified", true);
        history.go(0)
    }catch (error) {
        yield put(confirmEmailError(error))
    }
}

export function* resendVerifyEmail() {
    try {
        const response = yield call(resendVerificationEmail);
        yield put(resendVerificationEmailSuccess(response))
    }catch (error) {
        yield put(resendVerificationEmailError(error))
    }
}

export function* resetPasswordSaga({ payload: { password, history }}) {
    try {
        const response = yield call(resetPasswordRequest, password);
        yield put(resendVerificationEmailSuccess(response))
        localStorage.setItem("password_change", false);
        redirect(response, history)
    } catch (error) {
        yield put(resendVerificationEmailError(error))
    }
}

export function* forgotPasswordSaga({ payload: { email, history }}) {
    try {
        const response = yield call(forgotPasswordRequest, email);
        yield put(forgotPasswordSuccess(response))
    } catch (error) {
        yield put(forgotPasswordError(error))
    }
}

export function* passwordResetSaga({ payload: { data }}) {
    try {
        const response = yield call(passwordResetRequest, data);
        yield put(passwordResetSuccess(response))
    } catch (error) {
        yield put(passwordResetError(error))
    }
}

const redirect = (response, history) => {
    if (response.user?.role === "admin") {
        history.push(`/dashboard/companies`)
    } else {
        history.push(`/dashboard/active-ingredient`)
    }
}

function* authSaga() {
    yield takeEvery(LOGIN_REQUEST, loginRequest);
    yield takeEvery(REGISTER_REQUEST, regitser);
    yield takeEvery(VERIFY_EMAIL, verifyEmail);
    yield takeEvery(RESEND_EMAIL, resendVerifyEmail);
    yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordSaga);
    yield takeEvery(PASSWORD_RESET, passwordResetSaga);
    yield takeEvery(LOGOUT, logout);
    yield takeEvery(SET_AUTH_STATE, checkAuthorization);
}

function setUserStateLocalStorage(response) {
    localStorage.setItem("user", JSON.stringify(response.user));
    localStorage.setItem("user_verified", response.user?.user_verified);
    localStorage.setItem("password_change", response.user?.password_change);
    localStorage.setItem("role", response.user?.role);
    localStorage.setItem("has_api", response.user?.has_api);
}
function setLoginLocalStorage(response) {
    localStorage.setItem("token", response.token.access_token);
    localStorage.setItem("user", JSON.stringify(response.user));
    localStorage.setItem("user_verified", response.user?.user_verified);
    localStorage.setItem("password_change", response.user?.password_change);
    localStorage.setItem("role", response.user?.role);
    localStorage.setItem("has_api", response.user?.has_api);
}

function resetLoginLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_verified");
    localStorage.removeItem("password_change");
    localStorage.removeItem("role");
    localStorage.removeItem("has_api");
}


export default authSaga
