export const LOGOUT = "LOGOUT";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_VALIDATION_ERROR = 'REGISTER_VALIDATION_ERROR';

export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';

export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const SET_AUTH_SUCCESS = "SET_AUTH_SUCCESS";
export const SET_AUTH_FAIL = "SET_AUTH_FAIL";

export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";

export const RESEND_EMAIL = "RESEND_EMAIL";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_ERROR = "RESEND_EMAIL_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";

export const SUBTRACT_PRODUCTS_TRAIL_VIEW = "SUBTRACT_PRODUCTS_TRAIL_VIEW";
export const SUBTRACT_COMPANY_TRAIL_VIEW = "SUBTRACT_COMPANY_TRAIL_VIEW";

export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE";
