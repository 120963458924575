import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, Input, Row, Select, Popconfirm, Spin, Typography, DatePicker} from "antd";
import {validateFromServer} from "../../../../../library/helpers/validators/serverValidation";
import {
    clearState,
    createCompanySubscription,
    getSubscriptionPackages,
    getSubscriptionProducts, updateCompanySubscription
} from "../../../../../redux/company/actions";
import {getSubscriptionsProducts} from "../../../../../library/http/backendHelpers";
import moment, {now} from "moment";
import {showNotification} from "../../../../../redux/notification/actions";
import {clearState as clearStateCompanies} from "../../../../../redux/companies/actions";

const SubscriptionsForm = ({company_id}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {subscription, formLoading, error, products, packages} = useSelector(state => state.Company)
    const { Option } = Select;
    const {TextArea} = Input
    useEffect(() => {
        form.resetFields()
    }, [subscription])

    useEffect(() => {
        dispatch(getSubscriptionPackages())
        dispatch(getSubscriptionProducts())
    }, [subscription])

    const onFinish = (data) => {
        if (company_id) {
            const formData = {
                company_id: company_id,
                description: (data.description) ? data.description : '',
                product_id: data.product_id,
                start_period: (data.start_period) ? moment(data.start_period).format('YYYY-MM-DD HH:mm:ss') : (subscription.start_period) ?  moment(subscription.start_period).format('YYYY-MM-DD HH:mm:ss') :  moment().format('YYYY-MM-DD HH:mm:ss'),
                end_period: (data.end_period) ? moment(data.end_period).format('YYYY-MM-DD HH:mm:ss') : (subscription.end_period) ?  moment(subscription.end_period).format('YYYY-MM-DD HH:mm:ss') :  moment().format('YYYY-MM-DD HH:mm:ss')
            }
            if (Object.keys(subscription).length > 0) {
                dispatch(updateCompanySubscription(subscription.id, formData))
            } else {
               dispatch(createCompanySubscription(formData))
            }
        }
    }

    const notification = (type, message) => {
        dispatch(showNotification({ type: type, title: message }));
        dispatch(clearStateCompanies())
    };

    if (error) {
        if (error.code === 422){
            form.setFields(validateFromServer(error));
        }else{
            if (error !== "") {
                if (typeof error === 'string') {
                    notification("error", error);
                }
            }
        }
        dispatch(clearState());
    }



    return (
        <Spin spinning={formLoading}>
            <Form
                id="company_subscription_form"
                name="company_subscription_form"
                layout="vertical"
                form={form}
                style={{ width: "100%" }}
                onFinish={onFinish}
                initialValues={{
                    title: subscription.title,
                    description: subscription.description,
                    product_id: subscription.product_id,
                    start_period: (Object.keys(subscription).length > 0) ? moment(subscription.start_period):null,
                    end_period: (Object.keys(subscription).length > 0) ? moment(subscription.end_period):null
                }}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{ marginBottom: 0}}>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Product"
                                    name="product_id"
                                    rules={[{
                                        required: true,
                                        message: 'Please choose a product!'
                                    },
                                    ]}
                                >
                                    <Select
                                        placeholder="Choose Product"
                                        name="product_id"
                                        loading={formLoading}
                                        allowClear
                                        filterOption={false}
                                    >
                                        <Option value="disabled" disabled>
                                            Choose Product
                                        </Option>
                                        {products?.map(option => {
                                            return (<Option key={option.id} value={option.id}>{option.name}</Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label="Start Period"
                            name="start_period"
                            rules={[{
                                required: true,
                                message: 'Please input start period!'
                            },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} showTime defaultValue={(Object.keys(subscription).length > 0) && moment(subscription?.start_period)} placeholder={'Start Period'}/>
                        </Form.Item>
                        <Form.Item
                            label="End Period"
                            name="end_period"
                            rules={[{
                                required: true,
                                message: 'Please input end period!'
                            },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} showTime defaultValue={(Object.keys(subscription).length > 0) && moment(subscription?.end_period)} placeholder={'Start Period'}/>
                        </Form.Item>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Notes"
                                    name="description"
                                >
                                    <TextArea rows={3} style={{width: "100%"}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default SubscriptionsForm;
