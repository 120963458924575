export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const EDIT_COMPANY_VIEW_SUCCESS = "EDIT_COMPANY_VIEW_SUCCESS";

//Company Users
export const GET_COMPANY_USERS = "GET_COMPANY_USERS";
export const GET_COMPANY_USERS_SUCCESS = "GET_COMPANY_USERS_SUCCESS";
export const SET_COMPANY_USERS = "SET_COMPANY_USERS";

export const CREATE_COMPANY_USERS = "CREATE_COMPANY_USERS";
export const CREATE_COMPANY_USERS_SUCCESS = "CREATE_COMPANY_USERS_SUCCESS";
export const CREATE_COMPANY_USERS_FAIL = "CREATE_COMPANY_USERS_FAIL";

export const UPDATE_COMPANY_USERS = "UPDATE_COMPANY_USERS";
export const UPDATE_COMPANY_USERS_SUCCESS = "UPDATE_COMPANY_USERS_SUCCESS";
export const UPDATE_COMPANY_USERS_FAIL = "UPDATE_COMPANY_USERS_FAIL";

export const SENT_COMPANY_USER_VERIFICATION = "SENT_COMPANY_USER_VERIFICATION";
export const SENT_COMPANY_USER_VERIFICATION_SUCCESS = "SENT_COMPANY_USER_VERIFICATION_SUCCESS";
export const SENT_COMPANY_USER_VERIFICATION_FAIL = "SENT_COMPANY_USER_VERIFICATION_FAIL";

export const DELETE_COMPANY_USER = "DELETE_COMPANY_USER";
export const DELETE_COMPANY_USER_SUCCESS = "DELETE_COMPANY_USER_SUCCESS"

export const RESET_COMPANY_USERS_PASSWORD = "RESET_COMPANY_USERS_PASSWORD";
export const RESET_COMPANY_USERS_PASSWORD_SUCCESS = "RESET_COMPANY_USERS_PASSWORD_SUCCESS"


//Company Subscriptions
export const GET_COMPANY_SUBSCRIPTIONS = "GET_COMPANY_SUBSCRIPTIONS";
export const GET_COMPANY_SUBSCRIPTIONS_SUCCESS = "GET_COMPANY_SUBSCRIPTIONS_SUCCESS";
export const SET_COMPANY_SUBSCRIPTIONS = "SET_COMPANY_SUBSCRIPTIONS";

export const GET_SUBSCRIPTIONS_PRODUCTS = "GET_SUBSCRIPTIONS_PRODUCTS";
export const GET_SUBSCRIPTIONS_PRODUCTS_SUCCESS = "GET_SUBSCRIPTIONS_PRODUCTS_SUCCESS";

export const GET_SUBSCRIPTIONS_PACKAGES = "GET_SUBSCRIPTIONS_PACKAGES";
export const GET_SUBSCRIPTIONS_PACKAGES_SUCCESS = "GET_SUBSCRIPTIONS_PACKAGES_SUCCESS";

export const CREATE_COMPANY_SUBSCRIPTIONS = "CREATE_COMPANY_SUBSCRIPTIONS";
export const CREATE_COMPANY_SUBSCRIPTIONS_SUCCESS = "CREATE_COMPANY_SUBSCRIPTIONS_SUCCESS";
export const CREATE_COMPANY_SUBSCRIPTIONS_FAIL = "CREATE_COMPANY_SUBSCRIPTIONS_FAIL";

export const UPDATE_COMPANY_SUBSCRIPTIONS = "UPDATE_COMPANY_SUBSCRIPTIONS";
export const UPDATE_COMPANY_SUBSCRIPTIONS_SUCCESS = "UPDATE_COMPANY_SUBSCRIPTIONS_SUCCESS";
export const UPDATE_COMPANY_SUBSCRIPTIONS_FAIL = "UPDATE_COMPANY_SUBSCRIPTIONS_FAIL";

export const DELETE_COMPANY_SUBSCRIPTIONS = "DELETE_COMPANY_SUBSCRIPTIONS";
export const DELETE_COMPANY_SUBSCRIPTIONS_SUCCESS = "DELETE_COMPANY_SUBSCRIPTIONS_SUCCESS";
export const DELETE_COMPANY_SUBSCRIPTIONS_FAIL = "DELETE_COMPANY_SUBSCRIPTIONS_FAIL";

export const RENEW_COMPANY_SUBSCRIPTIONS = "RENEW_COMPANY_SUBSCRIPTIONS";
export const RENEW_COMPANY_SUBSCRIPTIONS_SUCCESS = "RENEW_COMPANY_SUBSCRIPTIONS_SUCCESS";
export const RENEW_COMPANY_SUBSCRIPTIONS_FAIL = "RENEW_COMPANY_SUBSCRIPTIONS_FAIL";

export const CANCEL_COMPANY_SUBSCRIPTIONS = "CANCEL_COMPANY_SUBSCRIPTIONS";
export const CANCEL_COMPANY_SUBSCRIPTIONS_SUCCESS = "CANCEL_COMPANY_SUBSCRIPTIONS_SUCCESS";
export const CANCEL_COMPANY_SUBSCRIPTIONS_FAIL = "CANCEL_COMPANY_SUBSCRIPTIONS_FAIL";

export const CREATE_COMPANY_TRAIL = "CREATE_COMPANY_TRAIL";
export const CREATE_COMPANY_TRAIL_SUCCESS = "CREATE_COMPANY_TRAIL_SUCCESS";
export const CREATE_COMPANY_TRAIL_FAIL = "CREATE_COMPANY_TRAIL_FAIL";

export const UPDATE_COMPANY_TRAIL = "UPDATE_COMPANY_TRAIL";
export const UPDATE_COMPANY_TRAIL_SUCCESS = "UPDATE_COMPANY_TRAIL_SUCCESS";
export const UPDATE_COMPANY_TRAIL_FAIL = "UPDATE_COMPANY_TRAIL_FAIL";

export const SET_NEW_COMPANY_USER_PASSWORD = "SET_NEW_COMPANY_USER_PASSWORD";
export const SET_NEW_COMPANY_USER_PASSWORD_SUCCESS = "SET_NEW_COMPANY_USER_PASSWORD_SUCCESS";
export const SET_NEW_COMPANY_USER_PASSWORD_FAIL = "SET_NEW_COMPANY_USER_PASSWORD_FAIL";

export const VERIFY_COMPANY_USER = "VERIFY_COMPANY_USER";
export const VERIFY_COMPANY_USER_SUCCESS = "VERIFY_COMPANY_USER_SUCCESS"

export const SET_VIEW_COMPANY = "SET_VIEW_COMPANY";

export const UPDATE_COMPANY_META = "UPDATE_COMPANY_META";

export const SET_PAGINATION_COMPANY_USERS = "SET_PAGINATION_COMPANY_USERS";
export const SET_PAGINATION_COMPANY_SUBSCRIPTIONS = "SET_PAGINATION_COMPANY_SUBSCRIPTIONS";

export const GET_COMPANY_DATA_ERROR = "GET_COMPANY_DATA_ERROR";
export const CLEAR_COMPANY_STATE = "CLEAR_COMPANY_STATE";
