import {
    GET_AI_VERIFIED_PRODUCERS,
    GET_AI_VERIFIED_PRODUCERS_SUCCESS,
    SET_PAGINATION_AI_VERIFIED_PRODUCERS,
    SET_PAGINATION_AI_RELATED_PRODUCERS,
    SET_AI_PROFILE_DATA,
    GET_AI_RELATED_PRODUCERS,
    GET_AI_RELATED_PRODUCERS_SUCCESS,
    GET_AI_PROFILE_DATA_ERROR,
    CLEAR_AI_PROFILE_STATE,
    GET_AI_OTHER_PRODUCERS,
    GET_AI_OTHER_PRODUCERS_SUCCESS,
    SET_PAGINATION_AI_OTHER_PRODUCERS,
    EXPORT_AI_PROFILE_EXCEL_REPORT,
    EXPORT_AI_PROFILE_EXCEL_REPORT_SUCCESS,
    EXPORT_AI_PROFILE_PDF_REPORT,
    EXPORT_AI_PROFILE_PDF_REPORT_SUCCESS,
    GET_AI_PROFILE_DATA,
    GET_AI_PROFILE_DATA_SUCCESS
} from "./actionTypes";

const initialState = {
    verified_producers: [],
    related_producers: [],
    other_producers: [],
    product: {},
    search_query: '',
    verified_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    related_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    other_pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    loadingVerified: false,
    loadingRelated: false,
    loadingOther: false,
    loadingProfile: false,
    exportLoading: false,
    success: '',
    error: '',
};

const ActiveIngredientsProfile = (state = initialState, action) => {
    switch (action.type) {
        case GET_AI_VERIFIED_PRODUCERS:
            return {
                ...state,
                loadingVerified: true
            };
        case GET_AI_VERIFIED_PRODUCERS_SUCCESS:
            return {
                ...state,
                verified_producers: action.payload,
                loadingVerified: false
            };
        case GET_AI_RELATED_PRODUCERS:
            return {
                ...state,
                loadingRelated: true
            };
        case GET_AI_RELATED_PRODUCERS_SUCCESS:
            return {
                ...state,
                related_producers: action.payload,
                loadingRelated: false
            };
        case GET_AI_OTHER_PRODUCERS:
            return {
                ...state,
                loadingOther: true
            };
        case GET_AI_OTHER_PRODUCERS_SUCCESS:
            return {
                ...state,
                other_producers: action.payload,
                loadingOther: false
            };
        case GET_AI_PROFILE_DATA:
            return {
                ...state,
                loadingProfile: true
            };
        case GET_AI_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                product: action.payload,
                loadingProfile: false
            };
        case EXPORT_AI_PROFILE_EXCEL_REPORT:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_AI_PROFILE_EXCEL_REPORT_SUCCESS:
            return {
                ...state,
                exportLoading: false
            };
        case EXPORT_AI_PROFILE_PDF_REPORT:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_AI_PROFILE_PDF_REPORT_SUCCESS:
            return {
                ...state,
                exportLoading: false
            };
        case SET_AI_PROFILE_DATA:
            return {
                ...state,
                product: action.payload
            };
        case SET_PAGINATION_AI_VERIFIED_PRODUCERS:
            return {
                ...state,
                verified_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case SET_PAGINATION_AI_RELATED_PRODUCERS:
            return {
                ...state,
                related_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case SET_PAGINATION_AI_OTHER_PRODUCERS:
            return {
                ...state,
                other_pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_AI_PROFILE_STATE:
            return {
                ...state,
                verified_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                related_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                other_pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                },
                loadingVerified: false,
                loadingRelated: false,
                loadingOther: false,
                loadingProfile: false,
                success: '',
                error: '',
            };
        case GET_AI_PROFILE_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingVerified: false,
                loadingRelated: false,
                loadingProfile: false,
                loadingOther: false,
            };
        default:
            return state;
    }
}

export default ActiveIngredientsProfile;
