import {
    GET_PACKAGES,
    GET_PACKAGES_SUCCESS,
    CREATE_PACKAGES,
    CREATE_PACKAGES_SUCCESS,
    CREATE_PACKAGES_FAIL,
    UPDATE_PACKAGES,
    UPDATE_PACKAGES_SUCCESS,
    UPDATE_PACKAGES_FAIL,
    DELETE_PACKAGES,
    DELETE_PACKAGES_SUCCESS,
    SET_PACKAGE,
    GET_PAGINATION_PACKAGES,
    GET_PACKAGES_DATA_ERROR,
    CLEAR_PACKAGES_STATE
} from "./actionTypes";


const initialState = {
    packages: [],
    single_package: {},
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    loading: false,
    formLoading: false,
    success: '',
    error: '',
};

const Packages = (state = initialState, action) => {
    switch (action.type) {
        case GET_PACKAGES:
            return {
                ...state,
                loading: true
            };
        case GET_PACKAGES_SUCCESS:
            return {
                ...state,
                packages: action.payload,
                loading: false
            }
        case CREATE_PACKAGES:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_PACKAGES_SUCCESS:
            return {
                ...state,
                success: 'Package was successfully created',
                packages: [action.payload, ...state.packages],
                formLoading: false
            };
        case CREATE_PACKAGES_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case UPDATE_PACKAGES:
            return {
                ...state,
                formLoading: true,
            };
        case UPDATE_PACKAGES_SUCCESS:
            return {
                ...state,
                packages: state.packages.map((packageData) => packageData.id === action.payload.id ? action.payload : packageData),
                success: "Package was successfully edited",
                formLoading: false,
            };
        case UPDATE_PACKAGES_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case DELETE_PACKAGES:
            return {
                ...state,
                loading: true
            };
        case DELETE_PACKAGES_SUCCESS:
            return {
                ...state,
                packages: state.packages.filter(packageData => packageData.id !== action.payload),
                success: "Package was successfully deleted",
                loading: false
            };
        case GET_PACKAGES_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case SET_PACKAGE:
            return {
                ...state,
                single_package: action.payload
            };
        case GET_PAGINATION_PACKAGES:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_PACKAGES_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default Packages;
