import {
    GET_COMPANY,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_USERS,
    GET_COMPANY_USERS_SUCCESS,
    GET_COMPANY_SUBSCRIPTIONS,
    GET_COMPANY_SUBSCRIPTIONS_SUCCESS,
    SET_PAGINATION_COMPANY_SUBSCRIPTIONS,
    SET_PAGINATION_COMPANY_USERS,
    GET_COMPANY_DATA_ERROR,
    CLEAR_COMPANY_STATE,
    SET_COMPANY_USERS,
    EDIT_COMPANY_VIEW_SUCCESS,
    CREATE_COMPANY_USERS,
    CREATE_COMPANY_USERS_SUCCESS,
    CREATE_COMPANY_USERS_FAIL,
    UPDATE_COMPANY_USERS,
    UPDATE_COMPANY_USERS_SUCCESS,
    UPDATE_COMPANY_USERS_FAIL,
    RESET_COMPANY_USERS_PASSWORD,
    RESET_COMPANY_USERS_PASSWORD_SUCCESS,
    DELETE_COMPANY_USER,
    DELETE_COMPANY_USER_SUCCESS,
    SET_COMPANY_SUBSCRIPTIONS,
    GET_SUBSCRIPTIONS_PRODUCTS,
    GET_SUBSCRIPTIONS_PRODUCTS_SUCCESS,
    GET_SUBSCRIPTIONS_PACKAGES,
    GET_SUBSCRIPTIONS_PACKAGES_SUCCESS,
    CREATE_COMPANY_SUBSCRIPTIONS,
    CREATE_COMPANY_SUBSCRIPTIONS_SUCCESS,
    CREATE_COMPANY_SUBSCRIPTIONS_FAIL,
    UPDATE_COMPANY_SUBSCRIPTIONS,
    UPDATE_COMPANY_SUBSCRIPTIONS_SUCCESS,
    UPDATE_COMPANY_SUBSCRIPTIONS_FAIL,
    UPDATE_COMPANY_META,
    RENEW_COMPANY_SUBSCRIPTIONS,
    RENEW_COMPANY_SUBSCRIPTIONS_SUCCESS,
    RENEW_COMPANY_SUBSCRIPTIONS_FAIL,
    CANCEL_COMPANY_SUBSCRIPTIONS,
    CANCEL_COMPANY_SUBSCRIPTIONS_SUCCESS,
    CANCEL_COMPANY_SUBSCRIPTIONS_FAIL,
    SENT_COMPANY_USER_VERIFICATION,
    SENT_COMPANY_USER_VERIFICATION_SUCCESS,
    SENT_COMPANY_USER_VERIFICATION_FAIL,
    SET_NEW_COMPANY_USER_PASSWORD,
    SET_NEW_COMPANY_USER_PASSWORD_SUCCESS,
    SET_NEW_COMPANY_USER_PASSWORD_FAIL,
    VERIFY_COMPANY_USER,
    VERIFY_COMPANY_USER_SUCCESS,
    CREATE_COMPANY_TRAIL,
    CREATE_COMPANY_TRAIL_SUCCESS,
    CREATE_COMPANY_TRAIL_FAIL,
    UPDATE_COMPANY_TRAIL,
    DELETE_COMPANY_SUBSCRIPTIONS,
    DELETE_COMPANY_SUBSCRIPTIONS_SUCCESS,
    UPDATE_COMPANY_TRAIL_SUCCESS, UPDATE_COMPANY_TRAIL_FAIL
} from "./actionTypes";

export const getCompany = (company_id) => ({
    type: GET_COMPANY,
    payload: { company_id }
});

export const getCompanySuccess = (data) => ({
    type: GET_COMPANY_SUCCESS,
    payload: data
});

export const editCompanyViewSuccess = (company) => ({
    type: EDIT_COMPANY_VIEW_SUCCESS,
    payload: company
});

//USERS
export const getCompanyUsers = (company_id, params) => ({
    type: GET_COMPANY_USERS,
    payload: { company_id, params }
});

export const getCompanyUsersSuccess = (data) => ({
    type: GET_COMPANY_USERS_SUCCESS,
    payload: data
});

export const setCompanyUser = user => ({
    type: SET_COMPANY_USERS,
    payload: user,
})

export const createCompanyUser = user => ({
    type: CREATE_COMPANY_USERS,
    payload: user
});

export const createCompanyUserSuccess = user => ({
    type: CREATE_COMPANY_USERS_SUCCESS,
    payload: user,
});

export const createCompanyUserFail = error => ({
    type: CREATE_COMPANY_USERS_FAIL,
    payload: error,
})

export const updateCompanyUser = (user, user_id) => ({
    type: UPDATE_COMPANY_USERS,
    payload: {user, user_id}
});

export const updateCompanyUserSuccess = user => ({
    type: UPDATE_COMPANY_USERS_SUCCESS,
    payload: user,
});

export const updateCompanyUserFail = error => ({
    type: UPDATE_COMPANY_USERS_FAIL,
    payload: error,
});

export const sentCompanyUserVerification = (user_id) => ({
    type: SENT_COMPANY_USER_VERIFICATION,
    payload: {user_id}
});

export const sentCompanyUserVerificationSuccess = user => ({
    type: SENT_COMPANY_USER_VERIFICATION_SUCCESS,
    payload: user,
});

export const sentCompanyUserVerificationFail = error => ({
    type: SENT_COMPANY_USER_VERIFICATION_FAIL,
    payload: error,
});

export const resetCompanyUserPassword = (user_id) => ({
    type: RESET_COMPANY_USERS_PASSWORD,
    payload: {user_id}
});

export const resetCompanyUserPasswordSuccess = () => ({
    type: RESET_COMPANY_USERS_PASSWORD_SUCCESS,
});

export const deleteCompanyUser = (user_id) => ({
    type: DELETE_COMPANY_USER,
    payload: {user_id}
});

export const deleteCompanyUserSuccess = (id) => ({
    type: DELETE_COMPANY_USER_SUCCESS,
    payload: id
});

//SUBSCRIPTIONS
export const getCompanySubscriptions = (company_id, params) => ({
    type: GET_COMPANY_SUBSCRIPTIONS,
    payload: { company_id, params }
});

export const getCompanySubscriptionsSuccess = (data) => ({
    type: GET_COMPANY_SUBSCRIPTIONS_SUCCESS,
    payload: data
});

export const setCompanySubscription = subscription => ({
    type: SET_COMPANY_SUBSCRIPTIONS,
    payload: subscription,
})

export const getSubscriptionProducts = () => ({
    type: GET_SUBSCRIPTIONS_PRODUCTS,
});

export const getSubscriptionProductsSuccess = (data) => ({
    type: GET_SUBSCRIPTIONS_PRODUCTS_SUCCESS,
    payload: data
});

export const getSubscriptionPackages = () => ({
    type: GET_SUBSCRIPTIONS_PACKAGES,
});

export const getSubscriptionPackagesSuccess = (data) => ({
    type: GET_SUBSCRIPTIONS_PACKAGES_SUCCESS,
    payload: data
});

export const createCompanySubscription = subscription => ({
    type: CREATE_COMPANY_SUBSCRIPTIONS,
    payload: subscription
});

export const createCompanySubscriptionSuccess = subscription => ({
    type: CREATE_COMPANY_SUBSCRIPTIONS_SUCCESS,
    payload: subscription,
});

export const createCompanySubscriptionFail = error => ({
    type: CREATE_COMPANY_SUBSCRIPTIONS_FAIL,
    payload: error,
})

export const updateCompanySubscription = (subscription_id, subscription) => ({
    type: UPDATE_COMPANY_SUBSCRIPTIONS,
    payload: {subscription_id, subscription}
});

export const updateCompanySubscriptionSuccess = subscription => ({
    type: UPDATE_COMPANY_SUBSCRIPTIONS_SUCCESS,
    payload: subscription,
});

export const updateCompanySubscriptionFail = error => ({
    type: UPDATE_COMPANY_SUBSCRIPTIONS_FAIL,
    payload: error,
});

export const deleteCompanySubscription = (subscription_id) => ({
    type: DELETE_COMPANY_SUBSCRIPTIONS,
    payload: { subscription_id }
});

export const deleteCompanySubscriptionSuccess = id => ({
    type: DELETE_COMPANY_SUBSCRIPTIONS_SUCCESS,
    payload: id,
});

export const renewCompanySubscription = (company_id, subscription_id) => ({
    type: RENEW_COMPANY_SUBSCRIPTIONS,
    payload: {company_id, subscription_id}
});

export const renewCompanySubscriptionSuccess = subscription => ({
    type: RENEW_COMPANY_SUBSCRIPTIONS_SUCCESS,
    payload: subscription,
});

export const renewCompanySubscriptionFail = error => ({
    type: RENEW_COMPANY_SUBSCRIPTIONS_FAIL,
    payload: error,
});


export const cancelCompanySubscription = (company_id, subscription_id) => ({
    type: CANCEL_COMPANY_SUBSCRIPTIONS,
    payload: {company_id, subscription_id}
});

export const cancelCompanySubscriptionSuccess = subscription => ({
    type: CANCEL_COMPANY_SUBSCRIPTIONS_SUCCESS,
    payload: subscription,
});

export const cancelCompanySubscriptionFail = error => ({
    type: CANCEL_COMPANY_SUBSCRIPTIONS_FAIL,
    payload: error,
});

export const setCompanySubscriptionsPagination = pagination => ({
    type: SET_PAGINATION_COMPANY_SUBSCRIPTIONS,
    payload: pagination,
});

export const updateCompanyMeta = data => ({
    type: UPDATE_COMPANY_META,
    payload: data,
});

export const setCompanyUsersPagination = pagination => ({
    type: SET_PAGINATION_COMPANY_USERS,
    payload: pagination,
});

export const setCompanyUserPassword = (password, user_id) => ({
    type: SET_NEW_COMPANY_USER_PASSWORD,
    payload: {password, user_id}
});

export const setCompanyUserPasswordSuccess = () => ({
    type: SET_NEW_COMPANY_USER_PASSWORD_SUCCESS,
});

export const setCompanyUserPasswordFail = error => ({
    type: SET_NEW_COMPANY_USER_PASSWORD_FAIL,
    payload: error,
});

export const verifyCompanyUser = (user_id) => ({
    type: VERIFY_COMPANY_USER,
    payload: {user_id}
});

export const verifyCompanyUserSuccess = (user) => ({
    type: VERIFY_COMPANY_USER_SUCCESS,
    payload: user,
});

export const dataError = error => ({
    type: GET_COMPANY_DATA_ERROR,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_COMPANY_STATE,
    payload: state
});

export const createCompanyTrail = trail => ({
    type: CREATE_COMPANY_TRAIL,
    payload: trail
});

export const createCompanyTrailSuccess = trail => ({
    type: CREATE_COMPANY_TRAIL_SUCCESS,
    payload: trail,
});

export const createCompanyTrailFail = error => ({
    type: CREATE_COMPANY_TRAIL_FAIL,
    payload: error,
})


export const updateCompanyTrail = (trail_id, trail) => ({
    type: UPDATE_COMPANY_TRAIL,
    payload: {trail_id, trail}
});

export const updateCompanyTrailSuccess = trail => ({
    type: UPDATE_COMPANY_TRAIL_SUCCESS,
    payload: trail,
});

export const updateCompanyTrailFail = error => ({
    type: UPDATE_COMPANY_TRAIL_FAIL,
    payload: error,
})
