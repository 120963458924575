import {
    GET_COMPANIES,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_DATA_ERROR,
    CLEAR_COMPANIES_STATE,
    SET_COMPANY,
    GET_PAGINATION_COMPANIES,
    CREATE_COMPANIES,
    CREATE_COMPANIES_SUCCESS,
    CREATE_COMPANIES_FAIL,
    UPDATE_COMPANIES,
    UPDATE_COMPANIES_SUCCESS,
    UPDATE_COMPANIES_FAIL,
    DELETE_COMPANIES,
    DELETE_COMPANIES_SUCCESS
} from "./actionTypes";

export const getCompanies = (params) => ({
    type: GET_COMPANIES,
    filters: {...params}
});

export const getCompaniesSuccess = (data) => ({
    type: GET_COMPANIES_SUCCESS,
    payload: data
});

export const createCompanies = company => ({
    type: CREATE_COMPANIES,
    payload: company
});

export const createCompaniesSuccess = company => ({
    type: CREATE_COMPANIES_SUCCESS,
    payload: company,
});

export const createCompaniesFail = error => ({
    type: CREATE_COMPANIES_FAIL,
    payload: error,
})

export const updateCompany = (company, company_id) => ({
    type: UPDATE_COMPANIES,
    payload: {company, company_id}
});

export const updateCompanySuccess = company => ({
    type: UPDATE_COMPANIES_SUCCESS,
    payload: company,
});

export const updateCompanyFail = error => ({
    type: UPDATE_COMPANIES_FAIL,
    payload: error,
});

export const deleteCompany = (company_id) => ({
    type: DELETE_COMPANIES,
    payload: {company_id}
});

export const deleteCompanySuccess = (id) => ({
    type: DELETE_COMPANIES_SUCCESS,
    payload: id
});


export const setCompany = (company) => ({
    type: SET_COMPANY,
    payload: company
});

export const setPagination = pagination => ({
    type: GET_PAGINATION_COMPANIES,
    payload: pagination,
});

export const dataError = error => ({
    type: GET_COMPANIES_DATA_ERROR,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_COMPANIES_STATE,
    payload: state
});
