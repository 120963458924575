import {
    SEARCH_COMPANIES,
    SEARCH_COMPANIES_SUCCESS,
    SEARCH_COMPANIES_FAIL,
    SET_COMPANY_SEARCH_QUERY,
    GET_PAGINATION_SEARCH_COMPANIES,
    CLEAR_SEARCH_COMPANIES_STATE
} from "./actionTypes";


const initialState = {
    companies: [],
    search_query: '',
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    loading: false,
    success: '',
    error: '',
};

const ProducersCompanies = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_COMPANIES:
            return {
                ...state,
                loading: true
            };
        case SEARCH_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                loading: false
            };
        case SEARCH_COMPANIES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case SET_COMPANY_SEARCH_QUERY:
            return {
                ...state,
                search_query: action.payload
            };
        case GET_PAGINATION_SEARCH_COMPANIES:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_SEARCH_COMPANIES_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                pagination: {
                    current: 1,
                    pageSize: 0,
                    total: 0
                }
            };
        default:
            return state;
    }
}

export default ProducersCompanies;
