import {
    SEARCH_PRODUCTS,
    SEARCH_PRODUCTS_SUCCESS,
    SEARCH_PRODUCTS_FAIL,
    SET_SEARCH_QUERY,
    CLEAR_SEARCH_PRODUCTS_STATE,
    GET_PAGINATION_SEARCH_PRODUCTS,
    GET_ACTIVE_INGREDIENT_IMAGE,
    GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS,
    GET_ACTIVE_INGREDIENT_IMAGE_FAIL
} from "./actionTypes";

export const searchActiveIngredients = (params) => ({
    type: SEARCH_PRODUCTS,
    filters: {...params}
});

export const searchActiveIngredientsSuccess = activeIngredients => ({
    type: SEARCH_PRODUCTS_SUCCESS,
    payload: activeIngredients,
});

export const searchActiveIngredientsFail = (error) => ({
    type: SEARCH_PRODUCTS_FAIL,
    payload: error,
});

export const setAiSearchQuery = (query) => ({
    type: SET_SEARCH_QUERY,
    payload: query,
});

export const getActiveIngredientImage = (product_id) => ({
    type: GET_ACTIVE_INGREDIENT_IMAGE,
    payload: {product_id}
});

export const getActiveIngredientImageSuccess = base64 => ({
    type: GET_ACTIVE_INGREDIENT_IMAGE_SUCCESS,
    payload: base64,
});

export const getActiveIngredientImageFail = error => ({
    type: GET_ACTIVE_INGREDIENT_IMAGE_FAIL,
    payload: error,
});

export const setPagination = pagination => ({
    type: GET_PAGINATION_SEARCH_PRODUCTS,
    payload: pagination,
});

export const clearState = (state) => ({
    type: CLEAR_SEARCH_PRODUCTS_STATE,
    payload: state
});
