export default {
  siteName: 'Becker Data',
  siteIcon: 'ion-flash',
  footerText: `Becker Data USA LLC © ${new Date().getFullYear()} All Rights Reserved`,
  enableAnimatedRoute: true,
  apiUrl: process.env.NODE_ENV == 'production' ? window.location.protocol + "//" + window.location.host + "/api"  : 'http://127.0.0.1:8001/api' ,
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
