import React from "react";
import {Table} from "antd";

const SynonymProducersModal = ({synonyms}) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'salt_name',
            sorter: true
        }
    ];
    return (
        <Table
            columns={columns}
            dataSource={synonyms}
            size="small" />
    )
}

export default SynonymProducersModal
