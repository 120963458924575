import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {closeModalMain} from '@iso/redux/rootModal/actions';
import UserForm from "../Users/Components/UserForm";
import ScheduleForm from "../Schedules/Components/ScheduleForm.js.temp";
import CompanyForm from "../Companies/Components/CompanyForm";
import CompanyUserForm from "../Companies/Company/CompanyUsers/Components/CompanyUserForm";
import SubscriptionsForm from "../Companies/Company/CompanySubscriptions/Components/SubscriptionsForm";
import PackageForm from "../Packages/Components/PackageForm";
import ProducersCommentsModal from "../../main/modals/ActiveIngredientsModals/ProducersCommentsModal";
import ProducersCompanyModal from "../../main/modals/ActiveIngredientsModals/ProducersCompanyModal";
import ProducersProductsModal from "../../main/modals/ActiveIngredientsModals/ProducersProductsModal";
import RcdDetailsModal from "../../main/modals/RcdModals/RcdDetailsModal";
import SynonymProducersModal from "../../main/modals/SynonymProducersModal";
import SetNewUserPassword from "../Users/Components/SetNewUserPassword";
import CompanySetNewUserPassword from "../Companies/Company/CompanyUsers/Components/CompanySetNewUserPassword";
import TrailForm from "../Companies/Company/CompanySubscriptions/Components/TrailForm";
import ProductChemicalStructureModal from "../../main/modals/ActiveIngredientsModals/ProductChemicalStructureModal";

const MODAL_COMPONENTS = {
    USER_FORM: UserForm,
    SCHEDULE_FORM: ScheduleForm,
    COMPANY_FORM: CompanyForm,
    COMPANY_USERS_FORM: CompanyUserForm,
    COMPANY_SUBSCRIPTIONS_FORM: SubscriptionsForm,
    PACKAGE_FORM: PackageForm,
    PRODUCERS_COMMENTS_MODAL: ProducersCommentsModal,
    PRODUCERS_COMPANY_MODAL: ProducersCompanyModal,
    PRODUCERS_PRODUCTS_MODAL: ProducersProductsModal,
    RCD_DETAILS_MODAL: RcdDetailsModal,
    PRODUCTS_SYNONYMS_MODAL: SynonymProducersModal,
    SET_NEW_USER_PASSWORD: SetNewUserPassword,
    SET_NEW_COMPANY_USER_PASSWORD: CompanySetNewUserPassword,
    TRAIL_FORM: TrailForm,
    CHEMICAL_STRUCTURE: ProductChemicalStructureModal
};


const RootModal = () => {

  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);
  const modalProps = useSelector((state) => state.rootModal.modalProps);
  const modalType = useSelector((state) => state.rootModal.modalType);
  const modalBodyProps = useSelector((state) => state.rootModal.modalBodyProps);

  const SpecificModal = MODAL_COMPONENTS[modalType];
    return(
      <Modal
        visible={modalVisibility}
        onCancel={() => dispatch(closeModalMain())}
       {...modalProps}
      >
        <SpecificModal  {...modalBodyProps} onCancel={() => dispatch(closeModalMain())} />
      </Modal>
    );

}


export default RootModal;
