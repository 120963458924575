import { call, takeEvery, put } from 'redux-saga/effects';
import {
    SEARCH_COMPANIES
} from "./actionTypes";
import {
    searchProducerCompaniesSuccess,
    searchProducerCompaniesFail,
    setPagination
} from "./actions";

import {
    searchProducersCompaniesRequest
} from '../../../library/http/backendHelpers'


function* searchProducerCompaniesSaga(params) {
    try {
        const response = yield call(searchProducersCompaniesRequest, params.filters);
        yield put(setPagination(response.meta))
        yield put(searchProducerCompaniesSuccess(response.data));
    }catch (error) {
        yield put(searchProducerCompaniesFail(error))
    }
}

function* producersCompaniesSaga() {
    yield takeEvery(SEARCH_COMPANIES, searchProducerCompaniesSaga)
}

export default producersCompaniesSaga
