export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"

export const GET_USERS_COMPANIES = "GET_USERS_COMPANIES";
export const GET_USERS_COMPANIES_SUCCESS = "GET_USERS_COMPANIES_SUCCESS"

export const SET_USER = "SET_USER";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAIL = "CHANGE_USER_PASSWORD_FAIL";

export const SENT_USER_VERIFICATION = "SENT_USER_VERIFICATION";
export const SENT_USER_VERIFICATION_SUCCESS = "SENT_USER_VERIFICATION_SUCCESS";
export const SENT_USER_VERIFICATION_FAIL = "SENT_USER_VERIFICATION_FAIL";

export const SET_NEW_USER_PASSWORD = "SET_NEW_USER_PASSWORD";
export const SET_NEW_USER_PASSWORD_SUCCESS = "SET_NEW_USER_PASSWORD_SUCCESS";
export const SET_NEW_USER_PASSWORD_FAIL = "SET_NEW_USER_PASSWORD_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"

export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS"

export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS"

export const GET_PAGINATION_USERS = "GET_PAGINATION_USERS";
export const DATA_ERROR_USERS = "DATA_ERROR_USERS";
export const CLEAR_USERS_STATE = "CLEAR_USERS_STATE";
