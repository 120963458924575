import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_COMPANIES,
    CREATE_COMPANIES,
    UPDATE_COMPANIES,
    DELETE_COMPANIES,
} from "./actionTypes";
import {
    getCompaniesSuccess,
    createCompaniesSuccess,
    createCompaniesFail,
    updateCompanySuccess,
    updateCompanyFail,
    dataError,
    setPagination, deleteCompanySuccess,
} from "./actions";

import {
    getCompanies,
    createCompaniesRequest,
    updateCompaniesRequest,
    deleteCompaniesRequest
} from '../../library/http/backendHelpers'
import {editCompanyViewSuccess} from "../company/actions";

function* fetchCompanies(params) {
    try {
        const response = yield call(getCompanies, params.filters);
        yield put(setPagination(response.meta))
        yield put(getCompaniesSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* createCompany({ payload: company }) {
    try {
        const response = yield call(createCompaniesRequest, company);
        yield put(createCompaniesSuccess(response.data));
    }catch (error) {
        yield put(createCompaniesFail(error))
    }
}

function* editCompany({payload: {company, company_id} }) {
    try {
        const response = yield call(updateCompaniesRequest, company, company_id);
        yield put(updateCompanySuccess(response.data));
        yield put(editCompanyViewSuccess(response.data))
    }catch (error) {
        yield put(updateCompanyFail(error))
    }
}

function* deleteCompany({ payload: {company_id}}) {
    try {
        const response = yield call(deleteCompaniesRequest, company_id);
        yield put(deleteCompanySuccess(company_id));
    }catch (error) {
        yield put(dataError(error))
    }
}


function* companiesSaga() {
    yield takeEvery(GET_COMPANIES, fetchCompanies)
    yield takeEvery(CREATE_COMPANIES, createCompany);
    yield takeEvery(UPDATE_COMPANIES, editCompany);
    yield takeEvery(DELETE_COMPANIES, deleteCompany);
}

export default companiesSaga
