import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import menuSaga from '@iso/redux/menu/saga'
import routesSaga from '@iso/redux/routes/saga'
import usersSaga from "@iso/redux/users/saga";
import profileSaga from "@iso/redux/profile/saga";
import scheduleSaga from "@iso/redux/schedules/saga";
import companiesSaga from "@iso/redux/companies/saga";
import companySaga from "@iso/redux/company/saga";
import packagesSaga from "@iso/redux/packages/saga";
import activeIngredientsSaga from "@iso/redux/main/activeIngredients/saga";
import activeIngredientsProfileSaga from "@iso/redux/main/activeIngredientProfile/saga";
import producersCompaniesSaga from "@iso/redux/main/producersCompanies/saga";
import producersCompaniesProfileSaga from "@iso/redux/main/producersCompaniesProfile/saga";
import modalInfoSaga from "@iso/redux/main/modalInfo/saga";
import appLogsSaga from "@iso/redux/appLogs/saga";
import exhibitionLogSaga from "@iso/redux/exhibitionLogs/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    menuSaga(),
    routesSaga(),
    usersSaga(),
    profileSaga(),
    scheduleSaga(),
    companiesSaga(),
    companySaga(),
    packagesSaga(),
    activeIngredientsSaga(),
    activeIngredientsProfileSaga(),
    producersCompaniesSaga(),
    producersCompaniesProfileSaga(),
    modalInfoSaga(),
    appLogsSaga(),
    exhibitionLogSaga()
  ]);
}
