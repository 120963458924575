import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Row, Select, Popconfirm, Spin, Typography, Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {validateFromServer} from "../../../../../library/helpers/validators/serverValidation";
import {createCompanyUser, resetCompanyUserPassword, updateCompanyUser, clearState} from "../../../../../redux/company/actions";

const CompanyUserForm = ({company_id}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {user, formLoading,error} = useSelector(state => state.Company)
    const {modalType, modalProps} = useSelector(state => state.rootModal)
    const [buttonType, setButtonType] = useState(0);
    const { Option } = Select;

    useEffect(() => {
        form.resetFields()
    }, [user])

    const onFinish = (data) => {
        const formData =  {
            company_id: company_id,
            address: (data.address) ? data.address: '',
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            phone: (data.phone) ? data.phone: '',
            with_verification: buttonType
        }
        if (company_id) {
            if (Object.keys(user).length > 0) {
               dispatch(updateCompanyUser(formData, user.id))
            } else {
                dispatch(createCompanyUser(formData))
            }
        }
    }
    if (error) {
        if (error.code === 422){
            form.setFields(validateFromServer(error));
        }
        dispatch(clearState());
    }
    const changePassword = () => {
        dispatch(resetCompanyUserPassword(user.id))
    }

    return (
        <Spin spinning={formLoading}>
            <Form
                id="company_users_form"
                name="company_users_form"
                layout="vertical"
                form={form}
                style={{ width: "100%" }}
                onFinish={onFinish}
                initialValues={user}
            >
                <Row gutter={24}>
                    <Col md={24} sm={12} xs={24} style={{ marginBottom: 0}}>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="First Name"
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input first name!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="First Name" name="first_name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input last name!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Last Name" name="last_name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input email!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" name="email" disabled={(Object.keys(user).length === 0) ? false: true}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24} style={{marginBottom: "10px"}}>
                                {Object.keys(user).length > 0 &&
                                    <Popconfirm
                                        title="Are you sure you want to reset this user password"
                                        onConfirm={changePassword}
                                        onCancel={() => {}}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button style={{width: "100%"}}>Reset Password</Button>
                                    </Popconfirm>
                                }
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Phone Number"
                                    name="phone"
                                >
                                    <Input placeholder="Phone Number" name="phone" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label="Address"
                                    name="address"
                                >
                                    <Input placeholder="Address" name="address" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} sm={4} xs={4}>
                    </Col>
                    <Col md={20} sm={20} xs={20} style={{textAlign: "right"}}>
                        {(modalType === 'COMPANY_USERS_FORM' && modalProps.title === 'Create User') ? <Space direction='horizontal'>
                            <Button form="company_users_form" key="submit" htmlType="submit" loading={formLoading} onClick={() => setButtonType(1)}>Add & Send Verification Email</Button>
                            <Button form="company_users_form" key="submit" htmlType="submit" type="primary" loading={formLoading} onClick={() => setButtonType(0)}>Add</Button>
                        </Space> : <Button form="company_users_form" key="submit" htmlType="submit" type="primary" loading={formLoading} onClick={() => setButtonType(0)}>Edit</Button>}
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default CompanyUserForm
