import {
    GET_COMMANDS,
    GET_COMMANDS_SUCCESS,
    GET_COMMANDS_ERROR,
    SET_LOADING,
    RESET_LOADING,
    GET_SCHEDULES_DATA_ERROR,
    CLEAR_SCHEDULES_STATE,
} from "./actionTypes";


const initialState = {
    schedules: [],
    main_loading: true,
    loading: {
        ai_producers: false,
        cgmp: false,
        cos: false,
        dmf: false,
        egmp: false,
        exhibitionCompanies: false,
        exhibitions: false,
        fda_inspection: false,
        igmp: false,
        jdmf: false,
        kdmf: false,
        vmf: false,
        who: false,
    },
    success: '',
    error: '',
};

const Schedules = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMMANDS:
            return {
                ...state,
                main_loading: true
            };
        case GET_COMMANDS_SUCCESS:
            return {
                ...state,
                schedules: action.payload,
                main_loading: false
            }
        case GET_COMMANDS_ERROR:
            return {
                ...state,
                schedules: action.payload,
                error: false
            }
        case SET_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [String(action.payload.command_name)]: true,
                },
            };
        case RESET_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [String(action.payload.command_name)]: false,
                },
            };
        case GET_SCHEDULES_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case CLEAR_SCHEDULES_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: {
                    ai_producers: false,
                    cgmp: false,
                    cos: false,
                    dmf: false,
                    egmp: false,
                    exhibitionCompanies: false,
                    exhibitions: false,
                    fda_inspection: false,
                    igmp: false,
                    jdmf: false,
                    kdmf: false,
                    vmf: false,
                    who: false,
                },
                formLoading: false
            };
        default:
            return state;
    }
}

export default Schedules;
