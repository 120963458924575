import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_COMPANY,
    GET_COMPANY_USERS,
    GET_COMPANY_SUBSCRIPTIONS,
    CREATE_COMPANY_USERS,
    UPDATE_COMPANY_USERS,
    RESET_COMPANY_USERS_PASSWORD,
    DELETE_COMPANY_USER,
    GET_SUBSCRIPTIONS_PRODUCTS,
    GET_SUBSCRIPTIONS_PACKAGES,
    CREATE_COMPANY_SUBSCRIPTIONS,
    UPDATE_COMPANY_SUBSCRIPTIONS,
    RENEW_COMPANY_SUBSCRIPTIONS,
    CANCEL_COMPANY_SUBSCRIPTIONS,
    SENT_COMPANY_USER_VERIFICATION,
    SET_NEW_COMPANY_USER_PASSWORD,
    VERIFY_COMPANY_USER,
    CREATE_COMPANY_TRAIL,
    UPDATE_COMPANY_TRAIL, DELETE_COMPANY_SUBSCRIPTIONS
} from "./actionTypes";

import {
    cancelCompanySubscriptionFail,
    cancelCompanySubscriptionSuccess,
    createCompanySubscriptionFail,
    createCompanySubscriptionSuccess, createCompanyTrailFail, createCompanyTrailSuccess,
    createCompanyUserFail,
    createCompanyUserSuccess,
    dataError, deleteCompanySubscriptionSuccess,
    deleteCompanyUserSuccess,
    editCompanyViewSuccess,
    getCompanySubscriptionsSuccess,
    getCompanySuccess,
    getCompanyUsersSuccess,
    getSubscriptionPackagesSuccess,
    getSubscriptionProductsSuccess, renewCompanySubscriptionFail,
    renewCompanySubscriptionSuccess,
    resetCompanyUserPasswordSuccess, sentCompanyUserVerificationFail, sentCompanyUserVerificationSuccess,
    setCompanySubscriptionsPagination, setCompanyUserPasswordFail, setCompanyUserPasswordSuccess,
    setCompanyUsersPagination,
    updateCompanyMeta,
    updateCompanySubscriptionFail,
    updateCompanySubscriptionSuccess, updateCompanyTrailFail, updateCompanyTrailSuccess,
    updateCompanyUserFail,
    updateCompanyUserSuccess, verifyCompanyUserSuccess,
} from "./actions";

import {
    createUsers,
    deleteUserRequest,
    editUser,
    getCompanyRequest,
    getCompanyUsersRequest,
    userPasswordChange,
    getCompanySubscriptionsRequest,
    getSubscriptionsProducts,
    getSubscriptionsPackage,
    createCompaniesSubscriptionRequest,
    editCompaniesSubscriptionRequest,
    renewCompaniesSubscriptionRequest,
    cancelCompaniesSubscriptionRequest,
    sentUserVerificationRequest,
    setNewUserPasswordRequest,
    verifyUserRequest,
    createCompaniesTrailRequest, updateCompaniesTrailRequest, deleteCompanySubscriptionRequest
} from '../../library/http/backendHelpers'
import {deleteCompanySuccess, updateCompanyFail, updateCompanySuccess} from "../companies/actions";

function* fetchCompany({ payload: { company_id } }) {
    try {
        const response = yield call(getCompanyRequest, company_id);
        yield put(getCompanySuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}
//USERS
function* fetchCompanyUsers({ payload: { company_id, params} }) {
    try {
        const response = yield call(getCompanyUsersRequest, company_id, params);
        yield put(setCompanyUsersPagination(response.meta));
        yield put(getCompanyUsersSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* createCompanyUser({ payload: user }) {
    try {
        const response = yield call(createUsers, user);
        yield put(createCompanyUserSuccess(response.data));
    }catch (error) {
        yield put(createCompanyUserFail(error))
    }
}

function* updateUser({ payload: {user, user_id}}) {
    try {
        const response = yield call(editUser, user, user_id);
        yield put(updateCompanyUserSuccess(response.data));
    }catch (error) {
        yield put(updateCompanyUserFail(error))
    }
}

function* deleteCompanyUser({ payload: {user_id}}) {
    try {
        const response = yield call(deleteUserRequest, user_id);
        yield put(deleteCompanyUserSuccess(user_id));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* resetCompanyUserPassword({ payload: {user_id}}) {
    try {
        const response = yield call(userPasswordChange, user_id);
        yield put(resetCompanyUserPasswordSuccess());
    }catch (error) {
        yield put(dataError(error))
    }
}
//SUBSCRIPTIONS
function* fetchCompanySubscriptions({ payload: { company_id, params} }) {
    try {
        const response = yield call(getCompanySubscriptionsRequest, company_id, params);
        yield put(setCompanySubscriptionsPagination(response.meta));
        yield put(getCompanySubscriptionsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* createCompanySubscription({ payload: subscription }) {
    try {
        const response = yield call(createCompaniesSubscriptionRequest, subscription);
        yield put(createCompanySubscriptionSuccess(response.data));
        yield put(updateCompanyMeta(response.meta))
    }catch (error) {
        yield put(createCompanySubscriptionFail(error))
    }
}

function* editCompanySubscription({payload: {subscription_id, subscription} }) {
   try {
       const response = yield call(editCompaniesSubscriptionRequest, subscription_id, subscription);
       yield put(updateCompanySubscriptionSuccess(response.data));
       yield put(updateCompanyMeta(response.meta))
   }catch (error) {
       yield put(updateCompanySubscriptionFail(error))
   }
}

function* renewCompanySubscriptionSaga({payload: {company_id, subscription_id} }) {
    try {
        const response = yield call(renewCompaniesSubscriptionRequest, company_id, subscription_id);
        yield put(renewCompanySubscriptionSuccess(response.data));
        yield put(updateCompanyMeta(response.meta))
    }catch (error) {
        yield put(renewCompanySubscriptionFail(error))
    }
}

function* cancelCompanySubscriptionSaga({payload: {company_id, subscription_id} }) {
    try {
        const response = yield call(cancelCompaniesSubscriptionRequest, company_id, subscription_id);
        yield put(cancelCompanySubscriptionSuccess(response.data));
        yield put(updateCompanyMeta(response.meta))
    }catch (error) {
        yield put(cancelCompanySubscriptionFail(error))
    }
}

function* fetchSubscriptionsProducts() {
    try {
        const response = yield call(getSubscriptionsProducts);
        yield put(getSubscriptionProductsSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* fetchSubscriptionsPackage() {
    try {
        const response = yield call(getSubscriptionsPackage);
        yield put(getSubscriptionPackagesSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}

function* sentCompanyUserVerificationSaga({ payload: {user_id}}) {
    try {
        const response = yield call(sentUserVerificationRequest, user_id);
        yield put(sentCompanyUserVerificationSuccess(response.data));
    }catch (error) {
        yield put(sentCompanyUserVerificationFail(error))
    }
}

function* setNewCompanyUserPasswordSaga({ payload: {password, user_id}}) {
    try {
        const response = yield call(setNewUserPasswordRequest, password, user_id);
        yield put(setCompanyUserPasswordSuccess());
    }catch (error) {
        yield put(setCompanyUserPasswordFail(error))
    }
}

function* verifyCompanyUserSaga({ payload: {user_id}}) {
    try {
        const response = yield call(verifyUserRequest, user_id);
        yield put(verifyCompanyUserSuccess(response.data));
    }catch (error) {
        yield put(dataError(error))
    }
}


function* createCompanyTrailSaga({ payload: trail }) {
    try {
        const response = yield call(createCompaniesTrailRequest, trail);
        yield put(createCompanyTrailSuccess(response.data));
        yield put(updateCompanyMeta(response.meta))
    }catch (error) {
        yield put(createCompanyTrailFail(error))
    }
}

function* editCompanyTrailSaga({payload: {trail_id, trail} }) {
    try {
        const response = yield call(updateCompaniesTrailRequest, trail_id, trail);
        yield put(updateCompanyTrailSuccess(response.data));
        yield put(updateCompanyMeta(response.meta))
    }catch (error) {
        yield put(updateCompanyTrailFail(error))
    }
}

function* deleteSubscriptionSaga({ payload: {subscription_id}}) {
    try {
        const response = yield call(deleteCompanySubscriptionRequest, subscription_id);
        yield put(deleteCompanySubscriptionSuccess(subscription_id));
        yield put(updateCompanyMeta(response.meta))
    }catch (error) {
        yield put(dataError(error))
    }
}
function* companySaga() {
    yield takeEvery(GET_COMPANY, fetchCompany)
    yield takeEvery(GET_COMPANY_USERS, fetchCompanyUsers);
    yield takeEvery(SENT_COMPANY_USER_VERIFICATION, sentCompanyUserVerificationSaga);
    //users
    yield takeEvery(CREATE_COMPANY_USERS, createCompanyUser);
    yield takeEvery(UPDATE_COMPANY_USERS, updateUser);
    yield takeEvery(DELETE_COMPANY_USER, deleteCompanyUser);
    yield takeEvery(RESET_COMPANY_USERS_PASSWORD, resetCompanyUserPassword);
    //subscriptions
    yield takeEvery(GET_SUBSCRIPTIONS_PRODUCTS, fetchSubscriptionsProducts);
    yield takeEvery(CREATE_COMPANY_SUBSCRIPTIONS, createCompanySubscription);
    yield takeEvery(UPDATE_COMPANY_SUBSCRIPTIONS, editCompanySubscription);
    yield takeEvery(GET_SUBSCRIPTIONS_PACKAGES, fetchSubscriptionsPackage);
    yield takeEvery(GET_COMPANY_SUBSCRIPTIONS, fetchCompanySubscriptions);

    yield takeEvery(RENEW_COMPANY_SUBSCRIPTIONS, renewCompanySubscriptionSaga);
    yield takeEvery(CANCEL_COMPANY_SUBSCRIPTIONS, cancelCompanySubscriptionSaga);


    yield takeEvery(SET_NEW_COMPANY_USER_PASSWORD, setNewCompanyUserPasswordSaga);
    yield takeEvery(VERIFY_COMPANY_USER, verifyCompanyUserSaga);

    yield takeEvery(CREATE_COMPANY_TRAIL, createCompanyTrailSaga);
    yield takeEvery(UPDATE_COMPANY_TRAIL, editCompanyTrailSaga);

    yield takeEvery(DELETE_COMPANY_SUBSCRIPTIONS, deleteSubscriptionSaga);

}

export default companySaga
