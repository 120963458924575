import {
    GET_PACKAGES,
    GET_PACKAGES_SUCCESS,
    CREATE_PACKAGES,
    CREATE_PACKAGES_SUCCESS,
    CREATE_PACKAGES_FAIL,
    UPDATE_PACKAGES,
    UPDATE_PACKAGES_SUCCESS,
    UPDATE_PACKAGES_FAIL,
    DELETE_PACKAGES,
    DELETE_PACKAGES_SUCCESS,
    SET_PACKAGE,
    GET_PAGINATION_PACKAGES,
    GET_PACKAGES_DATA_ERROR,
    CLEAR_PACKAGES_STATE
} from "./actionTypes";

export const getPackages = (params) => ({
    type: GET_PACKAGES,
    filters: {...params}
});

export const getPackagesSuccess = (data) => ({
    type: GET_PACKAGES_SUCCESS,
    payload: data
});

export const createPackage = packages => ({
    type: CREATE_PACKAGES,
    payload: packages
});

export const createPackageSuccess = packages => ({
    type: CREATE_PACKAGES_SUCCESS,
    payload: packages,
});

export const createPackageFail = error => ({
    type: CREATE_PACKAGES_FAIL,
    payload: error,
})

export const updatePackage = (packages, packages_id) => ({
    type: UPDATE_PACKAGES,
    payload: {packages, packages_id}
});

export const updatePackageSuccess = packages => ({
    type: UPDATE_PACKAGES_SUCCESS,
    payload: packages,
});

export const updatePackageFail = error => ({
    type: UPDATE_PACKAGES_FAIL,
    payload: error,
});

export const deletePackage = (packages_id) => ({
    type: DELETE_PACKAGES,
    payload: {packages_id}
});

export const deletePackageSuccess = (id) => ({
    type: DELETE_PACKAGES_SUCCESS,
    payload: id
});

export const setPackage = (packages) => ({
    type: SET_PACKAGE,
    payload: packages
});

export const setPagination = pagination => ({
    type: GET_PAGINATION_PACKAGES,
    payload: pagination,
});

export const dataError = error => ({
    type: GET_PACKAGES_DATA_ERROR,
    payload: error,
});

export const clearState = (state) => ({
    type: CLEAR_PACKAGES_STATE,
    payload: state
});
